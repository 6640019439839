import { useDisclosure } from '@chakra-ui/react';
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from 'constants/common';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import CustomDateInput from './CustomDateInput';

interface Props {
  placeholderText: string;
  dateFormat?: string;
  value?: string;
  onChange: any;
  selected: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date;
  tabIndex?: number;
  isDisabled?: boolean;
  showTimeSelect?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  timeCaption?: string;
  selectRange?: boolean;
  showMonthDropdown?: boolean;
  monthsShown?: number;
  showYearDropdown?: boolean;
}

const CustomReactDatePicker: React.FC<Props> = (props) => {
  const {
    dateFormat = REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
    selectRange = false,
    showTimeSelect = false,
    ...rest
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDatePicketModalClose = (event: any) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <ReactDatePicker
      open={isOpen}
      dateFormat={dateFormat}
      customInput={<CustomDateInput _placeholder={{ color: 'black' }} />}
      showTimeSelect={showTimeSelect}
      selectsRange={selectRange}
      shouldCloseOnSelect={true}
      autoComplete="off"
      onFocus={onOpen}
      onKeyDown={handleDatePicketModalClose}
      onClickOutside={onClose}
      {...rest}
    />
  );
};

export default CustomReactDatePicker;
