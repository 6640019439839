import Resource from 'api/resource';
import {
  BookingStatFilter,
  CustomerBookingStatsFilter,
} from 'constants/schema';
import http from 'utils/http';

class BookingDashboardResource extends Resource {
  constructor() {
    super('rms/membership-report');
  }

  customerBookings(query?: CustomerBookingStatsFilter) {
    return http({
      url: `${this.uri}/customer-booking`,
      method: 'GET',
      params: query,
    });
  }

  revenueSummary(query?: BookingStatFilter) {
    return http({
      url: `${this.uri}/customer-amount-spent`,
      method: 'GET',
      params: query,
    });
  }

  bookingNightCount(query?: BookingStatFilter) {
    return http({
      url: `${this.uri}/booking-night-count`,
      method: 'GET',
      params: { ...query, range: 1 },
    });
  }
  customerBookingNightCount(query?: BookingStatFilter) {
    return http({
      url: `${this.uri}/customer-booking-night-count`,
      method: 'GET',
      params: { ...query, range: 1 },
    });
  }
  customerBookingCount(query?: BookingStatFilter) {
    return http({
      url: `${this.uri}/customer-booking-count`,
      method: 'GET',
      params: { ...query, range: 1 },
    });
  }
}

export { BookingDashboardResource as default };
