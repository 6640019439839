import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ReservationDetail, ReservationList } from 'pages/dashboard/bms';
import BookingDetail from 'pages/dashboard/bms/Booking/BookingDetail';
import BookingList from 'pages/dashboard/bms/Booking/BookingList';
import CallbackDetail from 'pages/dashboard/bms/Callback/CallbackDetail';
import CallbackList from 'pages/dashboard/bms/Callback/CallbackList';
import BookingDashboard from 'pages/dashboard/bms/Dashboard/BookingDashboard';
import FerryBookingDetail from 'pages/dashboard/bms/FerryBooking/FerryBookingDetail';
import FerryBookingList from 'pages/dashboard/bms/FerryBooking/FerryBookingList';
import MarketingEmails from 'pages/dashboard/bms/MarketingEmails/MarketingEmails';
import MarketingEmailSettings from 'pages/dashboard/bms/MarketingEmails/MarketingEmailSettings';
import CheckInOutDetails from 'pages/dashboard/bms/OnBoarding/CheckInOutDetails';
import CheckInOutList from 'pages/dashboard/bms/OnBoarding/CheckInOutList';
import ReservationRequestDetail from 'pages/dashboard/bms/ReservationRequest/ReservationRequestDetail';
import ReservationRequestList from 'pages/dashboard/bms/ReservationRequest/ReservationRequestList';
import SecondScreen from 'pages/dashboard/bms/SecondScreen';
import { BreadBookingBillingList, BreadBookingList } from 'pages/dashboard/bs';
import AddBreadBooking from 'pages/dashboard/bs/BreadBooking/AddBreadBooking';
import BreadBookingDetail from 'pages/dashboard/bs/BreadBooking/BreadBookingDetail';
import BreadBookingBillingDetail from 'pages/dashboard/bs/BreadBookingBilling/BreadBookingBillingDetail';
import BreadBookingBillingEdit from 'pages/dashboard/bs/BreadBookingBilling/BreadBookingBillingEdit';
import BreadDailyViewDetail from 'pages/dashboard/bs/BreadDailyView/BreadDailyViewDetail';
import BreadDailyViewList from 'pages/dashboard/bs/BreadDailyView/BreadDailyViewList';
import DrivingOrderList from 'pages/dashboard/bs/DrivingOrder/DrivingOrderList';
import {
  ArchivedChat,
  ArchivedChatDetails,
  Chat,
  ChatMetrics,
  ClosedChat,
  ClosedChatDetails,
} from 'pages/dashboard/chat';
import TotalCallsList from 'pages/dashboard/contact-center/TotalCalls/TotalCallsList';
import CallForUser from 'pages/dashboard/contact-center/users/CallForUser';
import ContactCenterUserList from 'pages/dashboard/contact-center/users/ContactCenterUserList';
import {
  AddContact,
  ContactDetails,
  ContactList,
  MergeContact,
} from 'pages/dashboard/crm';
import {
  CustomerList,
  CustomersDocumentList,
  DMSObjectList,
  DMSUserList,
  EditCustomersDocument,
  EditGeneral,
  EditObjectsDocument,
  EditOwnersDocument,
  EditUsersDocument,
  GeneralList,
  ObjectsDocumentList,
  OwnerList,
  OwnersDocumentList,
  UploadCustomer,
  UploadCustomersDocument,
  UploadObject,
  UploadObjectsDocument,
  UploadOwner,
  UploadOwnersDocument,
  UploadUser,
  UsersDocumentList,
} from 'pages/dashboard/dms';
import CreateGeneralDocument from 'pages/dashboard/dms/General/UploadGeneral';
import UploadUsersDocument from 'pages/dashboard/dms/UserDocuments/UploadUsersDocument';
import {
  AddFaq,
  AddGroup,
  EditFaq,
  Faqs,
  GroupList,
  InternalFaqList,
} from 'pages/dashboard/faq';
import {
  AddQuestion,
  EditQuestion,
  FeedbackDetails,
  FeedbacksList,
  MailsList,
  QuestionsList,
  SettingList,
} from 'pages/dashboard/feedback';
import FeedbackAnalysis from 'pages/dashboard/feedback/Analysis/FeedbackAnalysis';
import FeedbackDashboard from 'pages/dashboard/feedback/Dashboards/FeedbackDashboard';
import {
  AddKey,
  EditKey,
  KeyDetails,
  KeyLists,
  LogInfo,
} from 'pages/dashboard/Key';
import KeyLogs from 'pages/dashboard/Key/KeyLogs/KeyLogs';
import {
  BookingsList,
  ContactData,
  InvoicesList,
  ServicesList,
  UploadFiles,
} from 'pages/dashboard/mdm';
import ImportsList from 'pages/dashboard/mdm/Imports/ImportsList';
import MDMLogs from 'pages/dashboard/mdm/Logs/MDMLogs';
import NewsletterRecipientList from 'pages/dashboard/mdm/Newsletter/NewsletterRecipientList';
import {
  AddArticle,
  AddType,
  AddUnit,
  AddWarehouse,
  ArticleDetails,
  Articles,
  CorrectInventory,
  EditWarehouse,
  Inventory,
  InventoryDetails,
  MyInventory,
  MyLogs,
  ObjectDetails,
  Objects,
  QRCode,
  Stocktaking,
  TransferItems,
  TransferLogs,
  TypeDetails,
  Types,
  UnitDetails,
  Units,
  ViewWarehouse,
  Warehouse,
} from 'pages/dashboard/mms';
import EditArticle from 'pages/dashboard/mms/Article/EditArticle';
import EditType from 'pages/dashboard/mms/ArticleType/EditType';
import EditUnit from 'pages/dashboard/mms/ArticleUnit/EditUnit';
import {
  ComposeMessage,
  DraftList,
  NewsletterList,
} from 'pages/dashboard/newsletter';
import EditNewsLetter from 'pages/dashboard/newsletter/Newsletters/EditNewsLetter';
import ViewNewsletterDetails from 'pages/dashboard/newsletter/Newsletters/ViewNewsletterDetails';
import AddCrossSelling from 'pages/dashboard/object-dashboard/cross-selling/AddCrossSelling';
import CrossSellingList from 'pages/dashboard/object-dashboard/cross-selling/CrossSellingList';
import EditCrossSelling from 'pages/dashboard/object-dashboard/cross-selling/EditCrossSelling';
import DashboardCustomerList from 'pages/dashboard/object-dashboard/dashboard/DashboardCustomerList';
import ListDashboard from 'pages/dashboard/object-dashboard/dashboard/ListDashboard';
import { ObjektDetails } from 'pages/dashboard/oms';
import ObjektList from 'pages/dashboard/oms/ObjektList';
import {
  CreditCardList,
  PaymentLinksList,
  PaypalList,
} from 'pages/dashboard/pms';
import InvoiceList from 'pages/dashboard/pms/Invoice/InvoiceList';
import CreatePaymentLink from 'pages/dashboard/pms/PaymentLinks/CreatePaymentLink';
import { PushNotificationList } from 'pages/dashboard/pns';
import AddPushNotification from 'pages/dashboard/pns/AddPushNotification';
import { EditProfile, MyProfile } from 'pages/dashboard/Profile';
import {
  RestaurantDetails,
  RestaurantList,
  RestaurantReservationDetails,
  RestaurantReservationList,
} from 'pages/dashboard/restaurant';
import AddRestaurant from 'pages/dashboard/restaurant/Restaurant/AddRestaurant';
import {
  ServiceDetail,
  ServiceGroupDetail,
  ServiceGroupList,
  ServiceList,
} from 'pages/dashboard/sms';
import AddMeerSylt from 'pages/dashboard/sms/MeerSylt/AddMeerSylt';
import MeerSyltDetails from 'pages/dashboard/sms/MeerSylt/MeerSyltDetails';
import MeerSyltList from 'pages/dashboard/sms/MeerSylt/MeerSyltList';
import AddRabatt from 'pages/dashboard/sms/Rabatt/AddRabatt';
import EditRabatt from 'pages/dashboard/sms/Rabatt/EditRabatt';
import RabattDetail from 'pages/dashboard/sms/Rabatt/RabattDetail';
import RabattList from 'pages/dashboard/sms/Rabatt/RabattList';
import AddServiceGroup from 'pages/dashboard/sms/ServiceGroups/AddServiceGroup';
import EditServiceGroup from 'pages/dashboard/sms/ServiceGroups/EditServiceGroup';
import AddService from 'pages/dashboard/sms/Services/AddService';
import EditService from 'pages/dashboard/sms/Services/EditService';
import { SplashScreen } from 'pages/dashboard/splashScreen';
import AddMembershipLevel from 'pages/dashboard/thirdPartyPartner/MembershipLevel/AddMembershipLevel';
import MembershipLevelDetails from 'pages/dashboard/thirdPartyPartner/MembershipLevel/MembershipLevelDetails';
import MembershipList from 'pages/dashboard/thirdPartyPartner/MembershipLevel/MembershipLevelList';
import AddThirdPartyPartner from 'pages/dashboard/thirdPartyPartner/ThirdPartyPartner/AddThirdPartyPartner';
import ThirdPartyPartnerDetails from 'pages/dashboard/thirdPartyPartner/ThirdPartyPartner/ThirdPartyPartnerDetails';
import ThirdPartyPartnerList from 'pages/dashboard/thirdPartyPartner/ThirdPartyPartner/ThirdPartyPartnerList';
import {
  CreateTicket,
  ObjectsInfo,
  TicketBoard,
  TicketList,
} from 'pages/dashboard/ticket';
import EditTicket from 'pages/dashboard/ticket/EditTicket';
import ViewTicket from 'pages/dashboard/ticket/ViewTicket';
import UserLoginLogList from 'pages/dashboard/users/UserLoginLogList';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AddNewCompany,
  AddNewUser,
  CompanyProfile,
  EditCompany,
  EditUser,
  ExternalCompanyList,
  UserList,
  UserProfile,
  UserRole,
  UserRoles,
} from '../pages/dashboard/users';
import RoleBasedRouting from './RoleBasedRouting';

const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.dashboard}
        component={() => <Redirect to={routes.splash} />}
      />
      <Route exact path={routes.splash} component={SplashScreen} />
      <Route exact path={routes.profile.myProfile} component={MyProfile} />
      <Route exact path={routes.profile.edit} component={EditProfile} />
      <Route exact path={routes.secondScreen} component={SecondScreen} />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.list}
        component={UserList}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.add}
        component={AddNewUser}
        allowedPermissions={[
          PermissionRequest['read:user'],
          PermissionRequest['manage:user'],
        ]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.edit}
        component={EditUser}
        allowedPermissions={[PermissionRequest['manage:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.list}
        component={UserRoles}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.view}
        component={UserRole}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.profile}
        component={UserProfile}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.list}
        component={ExternalCompanyList}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.add}
        component={AddNewCompany}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.edit}
        component={EditCompany}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.externalCompanies.profile}
        component={CompanyProfile}
        allowedPermissions={[PermissionRequest['manage:external-companies']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.loginLog.list}
        component={UserLoginLogList}
        allowedPermissions={[PermissionRequest['read:login-log']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.default}
        component={Chat}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.closed.list}
        component={ClosedChat}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.closed.details}
        component={ClosedChatDetails}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.archived.list}
        component={ArchivedChat}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.archived.details}
        component={ArchivedChatDetails}
        allowedPermissions={[PermissionRequest['read:chat']]}
      />
      <RoleBasedRouting
        serviceName="chat-service"
        exact
        path={routes.chat.metrics}
        component={ChatMetrics}
        allowedPermissions={[PermissionRequest['read:chat-metrics']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.task.board}
        component={TicketBoard}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.list.default}
        component={TicketList}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.view, routes.ticket.list.view]}
        component={ViewTicket}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.objectsInfo}
        component={ObjectsInfo}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.create, routes.ticket.list.create]}
        component={CreateTicket}
        allowedPermissions={[PermissionRequest['create:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.edit, routes.ticket.list.edit]}
        component={EditTicket}
        allowedPermissions={[PermissionRequest['edit:ticket']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.contact.list}
        component={ContactList}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={[routes.crm.contact.add, routes.crm.contact.edit]}
        component={AddContact}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.contact.details}
        component={ContactDetails}
        allowedPermissions={[PermissionRequest['read:crm']]}
      />
      <RoleBasedRouting
        serviceName="crm-service"
        exact
        path={routes.crm.merge.list}
        component={MergeContact}
        allowedPermissions={[PermissionRequest['merge:contacts']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.inventory.list}
        component={Inventory}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.inventory.details}
        component={InventoryDetails}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.transferItems}
        component={TransferItems}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.articles.list}
        component={Articles}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.articles.add}
        component={AddArticle}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.articles.edit}
        component={EditArticle}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.articles.details}
        component={ArticleDetails}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.objects.list}
        component={Objects}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.objects.details}
        component={ObjectDetails}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.warehouse.list}
        component={Warehouse}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.warehouse.add}
        component={AddWarehouse}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.warehouse.edit}
        component={EditWarehouse}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.warehouse.view}
        component={ViewWarehouse}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.types.list}
        component={Types}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.types.add}
        component={AddType}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.types.details}
        component={TypeDetails}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.types.edit}
        component={EditType}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.units.list}
        component={Units}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.units.add}
        component={AddUnit}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.units.edit}
        component={EditUnit}
        allowedPermissions={[PermissionRequest['manage:article']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.units.details}
        component={UnitDetails}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.transferLogs}
        component={TransferLogs}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.stocktaking.stocktakingMode}
        component={Stocktaking}
        allowedPermissions={[PermissionRequest['manage:stocktaking']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.stocktaking.correctInventory}
        component={CorrectInventory}
        allowedPermissions={[PermissionRequest['manage:stocktaking']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.myInventory.list}
        component={MyInventory}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.myLogs.list}
        component={MyLogs}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mms-service"
        exact
        path={routes.mms.qrCode.list}
        component={QRCode}
        allowedPermissions={[PermissionRequest['read:mms']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.uploads.list}
        component={UploadFiles}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.contacts.list}
        component={ContactData}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      {/* <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.objects.list}
        component={ObjectList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      /> */}
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.bookings.list}
        component={BookingsList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.invoices.list}
        component={InvoicesList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.services.list}
        component={ServicesList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.newsletter.list}
        component={NewsletterRecipientList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.imports.list}
        component={ImportsList}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="mdm-service"
        exact
        path={routes.mdm.logs.list}
        component={MDMLogs}
        allowedPermissions={[PermissionRequest['read:mdm']]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.list}
        component={ObjektList}
        allowedPermissions={[PermissionRequest['read:oms']]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.details}
        component={ObjektDetails}
        allowedPermissions={[PermissionRequest['read:oms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.list}
        component={ServiceList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.add}
        component={AddService}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.edit}
        component={EditService}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service"
        exact
        path={routes.sms.services.details}
        component={ServiceDetail}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.list}
        component={ServiceGroupList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.add}
        component={AddServiceGroup}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.edit}
        component={EditServiceGroup}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.serviceGroups.details}
        component={ServiceGroupDetail}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="bms-reservation"
        exact
        path={routes.bms.reservation.list}
        component={ReservationList}
        allowedPermissions={[PermissionRequest['read:reservations']]}
      />
      <RoleBasedRouting
        serviceName="bms-reservation"
        exact
        path={routes.bms.reservation.details}
        component={ReservationDetail}
        allowedPermissions={[PermissionRequest['read:reservations']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking"
        exact
        path={routes.bs.breadBooking.list}
        component={BreadBookingList}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking"
        exact
        path={[routes.bs.breadBooking.add, routes.bs.breadBooking.edit]}
        component={AddBreadBooking}
        allowedPermissions={[PermissionRequest['manage:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking"
        exact
        path={routes.bs.breadBooking.details}
        component={BreadBookingDetail}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-driving-order"
        exact
        path={routes.bs.drivingOrder.list}
        component={DrivingOrderList}
        allowedPermissions={[PermissionRequest['read:bread-driving-order']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-daily-view"
        exact
        path={routes.bs.breadDailyView.list}
        component={BreadDailyViewList}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-daily-view"
        exact
        path={routes.bs.breadDailyView.details}
        component={BreadDailyViewDetail}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking-billing-view"
        exact
        path={routes.bs.breadBookingBilling.list}
        component={BreadBookingBillingList}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking-billing-view"
        exact
        path={routes.bs.breadBookingBilling.details}
        component={BreadBookingBillingDetail}
        allowedPermissions={[PermissionRequest['read:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bs-bread-booking-billing-view"
        exact
        path={routes.bs.breadBookingBilling.edit}
        component={BreadBookingBillingEdit}
        allowedPermissions={[PermissionRequest['manage:bread-service']]}
      />
      <RoleBasedRouting
        serviceName="bms-reservation-request"
        exact
        path={routes.bms.reservationRequest.list}
        component={ReservationRequestList}
        allowedPermissions={[PermissionRequest['read:reservations']]}
      />
      <RoleBasedRouting
        serviceName="bms-reservation-request"
        exact
        path={routes.bms.reservationRequest.details}
        component={ReservationRequestDetail}
        allowedPermissions={[PermissionRequest['read:reservations']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.list}
        component={BookingList}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-booking"
        exact
        path={routes.bms.booking.details}
        component={BookingDetail}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-ferry-booking"
        exact
        path={routes.bms.ferryBooking.list}
        component={FerryBookingList}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-ferry-booking"
        exact
        path={routes.bms.ferryBooking.details}
        component={FerryBookingDetail}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="Booking"
        exact
        path={routes.bms.dashboard.list}
        component={BookingDashboard}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />

      <RoleBasedRouting
        serviceName="bms-callback"
        exact
        path={routes.bms.callback.list}
        component={CallbackList}
        allowedPermissions={[PermissionRequest['read:callbacks']]}
      />
      <RoleBasedRouting
        serviceName="bms-callback"
        exact
        path={routes.bms.callback.details}
        component={CallbackDetail}
        allowedPermissions={[PermissionRequest['read:callbacks']]}
      />
      <RoleBasedRouting
        serviceName="bms-check-in-out"
        exact
        path={routes.bms.checkInOut.list}
        component={CheckInOutList}
        allowedPermissions={[PermissionRequest['read:booking-terminals']]}
      />
      <RoleBasedRouting
        serviceName="bms-check-in-out"
        exact
        path={routes.bms.checkInOut.details}
        component={CheckInOutDetails}
        allowedPermissions={[PermissionRequest['read:booking-terminals']]}
      />
      <RoleBasedRouting
        serviceName="bms-marketing-email"
        exact
        path={routes.bms.marketingEmail.list}
        component={MarketingEmails}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="bms-marketing-email-settings"
        exact
        path={routes.bms.marketingEmailSettings.list}
        component={MarketingEmailSettings}
        allowedPermissions={[PermissionRequest['read:bookings']]}
      />
      <RoleBasedRouting
        serviceName="membership-level"
        exact
        path={routes.thirdPartyPartner.membershipLevel.list}
        component={MembershipList}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />
      <RoleBasedRouting
        serviceName="membership-level"
        exact
        path={[
          routes.thirdPartyPartner.membershipLevel.add,
          routes.thirdPartyPartner.membershipLevel.edit,
        ]}
        component={AddMembershipLevel}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />
      <RoleBasedRouting
        serviceName="third-party-partner"
        exact
        path={routes.thirdPartyPartner.membershipLevel.details}
        component={MembershipLevelDetails}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />
      <RoleBasedRouting
        serviceName="third-party-partner"
        exact
        path={routes.thirdPartyPartner.thirdPartyPartner.list}
        component={ThirdPartyPartnerList}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />
      <RoleBasedRouting
        serviceName="third-party-partner"
        exact
        path={[
          routes.thirdPartyPartner.thirdPartyPartner.add,
          routes.thirdPartyPartner.thirdPartyPartner.edit,
        ]}
        component={AddThirdPartyPartner}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />
      <RoleBasedRouting
        serviceName="third-party-partner"
        exact
        path={routes.thirdPartyPartner.thirdPartyPartner.details}
        component={ThirdPartyPartnerDetails}
        allowedPermissions={[PermissionRequest['manage:third-party-partner']]}
      />

      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.list}
        component={Faqs}
        allowedPermissions={[PermissionRequest['read:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.add}
        component={AddFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.edit}
        component={EditFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="internal-faq"
        exact
        path={routes.faq.internalfaq.list}
        component={InternalFaqList}
        allowedPermissions={[PermissionRequest['read:internal-faq']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={routes.faq.faqGroup.list}
        component={GroupList}
        allowedPermissions={[PermissionRequest['read:faq-group']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={[routes.faq.faqGroup.add, routes.faq.faqGroup.edit]}
        component={AddGroup}
        allowedPermissions={[PermissionRequest['manage:faq-group']]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.list}
        component={GeneralList}
        allowedPermissions={[PermissionRequest['read:dms']]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.upload}
        component={CreateGeneralDocument}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="general-list"
        exact
        path={routes.dms.generalList.edit}
        component={EditGeneral}
        allowedPermissions={[PermissionRequest['manage:dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.list}
        component={CustomersDocumentList}
        allowedPermissions={[PermissionRequest['read:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.upload}
        component={UploadCustomersDocument}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.edit}
        component={EditCustomersDocument}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.customer.list}
        component={CustomerList}
        allowedPermissions={[PermissionRequest['read:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="customer-documents"
        exact
        path={routes.dms.customerDocuments.customer.upload}
        component={UploadCustomer}
        allowedPermissions={[PermissionRequest['manage:customer-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.list}
        component={ObjectsDocumentList}
        allowedPermissions={[PermissionRequest['read:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.upload}
        component={UploadObjectsDocument}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.edit}
        component={EditObjectsDocument}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.objects.list}
        component={DMSObjectList}
        allowedPermissions={[PermissionRequest['read:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="object-documents"
        exact
        path={routes.dms.objectDocuments.objects.upload}
        component={UploadObject}
        allowedPermissions={[PermissionRequest['manage:object-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.list}
        component={OwnersDocumentList}
        allowedPermissions={[PermissionRequest['read:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.upload}
        component={UploadOwnersDocument}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.edit}
        component={EditOwnersDocument}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.owner.list}
        component={OwnerList}
        allowedPermissions={[PermissionRequest['read:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.ownerDocuments.owner.upload}
        component={UploadOwner}
        allowedPermissions={[PermissionRequest['manage:owner-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.user.list}
        component={DMSUserList}
        allowedPermissions={[PermissionRequest['read:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.user.upload}
        component={UploadUser}
        allowedPermissions={[PermissionRequest['manage:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.list}
        component={UsersDocumentList}
        allowedPermissions={[PermissionRequest['read:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.upload}
        component={UploadUsersDocument}
        allowedPermissions={[PermissionRequest['manage:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="user-documents"
        exact
        path={routes.dms.userDocuments.edit}
        component={EditUsersDocument}
        allowedPermissions={[PermissionRequest['manage:user-dms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.creditCard.list}
        component={CreditCardList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paypal.list}
        component={PaypalList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.invoice.list}
        component={InvoiceList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paymentLinks.list}
        component={PaymentLinksList}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pms-service"
        exact
        path={routes.pms.paymentLinks.add}
        component={CreatePaymentLink}
        allowedPermissions={[PermissionRequest['read:pms']]}
      />
      <RoleBasedRouting
        serviceName="pns-service"
        exact
        path={routes.pns.notification.list}
        component={PushNotificationList}
        allowedPermissions={[PermissionRequest['read:pns']]}
      />
      <RoleBasedRouting
        serviceName="pns-service"
        exact
        path={routes.pns.notification.add}
        component={AddPushNotification}
        allowedPermissions={[PermissionRequest['send:push-notificaton']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.members.list}
        component={ContactCenterUserList}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.members.profile}
        component={CallForUser}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="contact-center-service"
        exact
        path={routes.contactCenter.totalCalls.list}
        component={TotalCallsList}
        allowedPermissions={[PermissionRequest['read:contact-center']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.dashboard}
        component={FeedbackDashboard}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.analysis}
        component={FeedbackAnalysis}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.feedbacks.list}
        component={FeedbacksList}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.feedbacks.details}
        component={FeedbackDetails}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.mails.list}
        component={MailsList}
        allowedPermissions={[PermissionRequest['read:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.list}
        component={QuestionsList}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.add}
        component={AddQuestion}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.questions.edit}
        component={EditQuestion}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="feed-service"
        exact
        path={routes.feedback.setting.list}
        component={SettingList}
        allowedPermissions={[PermissionRequest['manage:feedback']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.list}
        component={NewsletterList}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.add}
        component={ComposeMessage}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.edit}
        component={EditNewsLetter}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.draft.list}
        component={DraftList}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.view}
        component={ViewNewsletterDetails}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.KeyLogs.list}
        component={KeyLogs}
        allowedPermissions={[PermissionRequest['read:keylog']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.KeyLogs.view}
        component={LogInfo}
        allowedPermissions={[PermissionRequest['read:keylog']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.list}
        component={KeyLists}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.view}
        component={KeyDetails}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.add}
        component={AddKey}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="key-service"
        exact
        path={routes.key.keyList.edit}
        component={EditKey}
        allowedPermissions={[PermissionRequest['read:keylist']]}
      />
      <RoleBasedRouting
        serviceName="object-dashboard"
        exact
        path={routes.objectDashboard.dashboard.contactList}
        component={DashboardCustomerList}
        allowedPermissions={[PermissionRequest['read:object-dashboard']]}
      />
      <RoleBasedRouting
        serviceName="object-dashboard"
        exact
        path={routes.objectDashboard.dashboard.board}
        component={ListDashboard}
        allowedPermissions={[PermissionRequest['read:object-dashboard']]}
      />
      <RoleBasedRouting
        serviceName="object-dashboard"
        exact
        path={routes.objectDashboard.crossSelling.list}
        component={CrossSellingList}
        allowedPermissions={[PermissionRequest['manage:cross-selling']]}
      />
      <RoleBasedRouting
        serviceName="object-dashboard"
        exact
        path={routes.objectDashboard.crossSelling.add}
        component={AddCrossSelling}
        allowedPermissions={[PermissionRequest['manage:cross-selling']]}
      />
      <RoleBasedRouting
        serviceName="object-dashboard"
        exact
        path={routes.objectDashboard.crossSelling.edit}
        component={EditCrossSelling}
        allowedPermissions={[PermissionRequest['manage:cross-selling']]}
      />
      <RoleBasedRouting
        serviceName="restaurant"
        exact
        path={routes.restaurant.restaurant.list}
        component={RestaurantList}
        allowedPermissions={[PermissionRequest['manage:restaurant']]}
      />
      <RoleBasedRouting
        serviceName="restaurant"
        exact
        path={[
          routes.restaurant.restaurant.add,
          routes.restaurant.restaurant.edit,
        ]}
        component={AddRestaurant}
        allowedPermissions={[PermissionRequest['manage:restaurant']]}
      />
      <RoleBasedRouting
        serviceName="restaurant"
        exact
        path={routes.restaurant.restaurant.details}
        component={RestaurantDetails}
        allowedPermissions={[PermissionRequest['manage:restaurant']]}
      />
      <RoleBasedRouting
        serviceName="restaurant"
        exact
        path={routes.restaurant.restaurantReservation.list}
        component={RestaurantReservationList}
        allowedPermissions={[PermissionRequest['manage:restaurant']]}
      />

      <RoleBasedRouting
        serviceName="restaurant"
        exact
        path={routes.restaurant.restaurantReservation.details}
        component={RestaurantReservationDetails}
        allowedPermissions={[PermissionRequest['manage:restaurant']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.meerSylt.list}
        component={MeerSyltList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={[routes.sms.meerSylt.add, routes.sms.meerSylt.edit]}
        component={AddMeerSylt}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.meerSylt.details}
        component={MeerSyltDetails}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.rabatt.list}
        component={RabattList}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.rabatt.add}
        component={AddRabatt}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.rabatt.edit}
        component={EditRabatt}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
      <RoleBasedRouting
        serviceName="sms-service-group"
        exact
        path={routes.sms.rabatt.details}
        component={RabattDetail}
        allowedPermissions={[PermissionRequest['read:sms']]}
      />
    </Switch>
  );
};

export default PrivateRouter;
