import { InputGroup, InputRightElement } from '@chakra-ui/input';
import { Flex } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from 'constants/common';
import { BookingDashboardFilterParams } from 'constants/schema';
import React, { SetStateAction, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

const getThisYearDates = () => {
  const start = new Date(new Date().getFullYear(), 0, 1);
  const end = new Date(new Date().getFullYear(), 11, 31);
  return [start, end];
};

const getLastYearDates = () => {
  const lastYear = new Date().getFullYear() - 1;
  const start = new Date(lastYear, 0, 1);
  const end = new Date(lastYear, 11, 31);
  return [start, end];
};

const getCurrentSemesterDates = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const isFirstSemester = currentMonth < 6;

  const start = new Date(currentYear, isFirstSemester ? 0 : 6, 1);
  const end = new Date(
    currentYear,
    isFirstSemester ? 5 : 11,
    isFirstSemester ? 30 : 31
  );
  return [start, end];
};

const getCurrentQuarterDates = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const quarter = Math.floor(currentMonth / 3);
  const start = new Date(currentYear, quarter * 3, 1);
  const end = new Date(currentYear, quarter * 3 + 2, [31, 31, 31, 30][quarter]);
  return [start, end];
};

const SELECT_OPTIONS = [
  'this_year',
  'last_year',
  'current_semester',
  'current_quarter',
] as const;

type SelectOptionType = typeof SELECT_OPTIONS[number];

type Props = {
  filterParams: BookingDashboardFilterParams;
  setFilterParams: React.Dispatch<SetStateAction<BookingDashboardFilterParams>>;
};

const BookingDashboardSearch: React.FC<Props> = (props) => {
  const { filterParams, setFilterParams } = props;
  const [startDate, setStartDate] = useState<Date | null>(
    filterParams?.from_date ? new Date(filterParams.from_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    filterParams?.to_date ? new Date(filterParams.to_date) : null
  );

  const [selectedOption, setSelectedOption] = useState<
    SelectOptionType | undefined
  >(undefined);

  const onChange = (dates: [Date, Date]) => {
    if (!dates) {
      setStartDate(null);
      setEndDate(null);
      setFilterParams((prev) => ({
        ...prev,
        from_date: '',
        to_date: '',
      }));
      return;
    }
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setSelectedOption(undefined);
      setFilterParams((prev) => ({
        ...prev,
        from_date: DateFormatYMD(start),
        to_date: DateFormatYMD(end),
      }));
    }
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as SelectOptionType;
    setSelectedOption(value);

    let newStartDate: Date | null = null;
    let newEndDate: Date | null = null;

    switch (value) {
      case 'this_year':
        [newStartDate, newEndDate] = getThisYearDates();
        break;
      case 'last_year':
        [newStartDate, newEndDate] = getLastYearDates();
        break;
      case 'current_semester':
        [newStartDate, newEndDate] = getCurrentSemesterDates();
        break;
      case 'current_quarter':
        [newStartDate, newEndDate] = getCurrentQuarterDates();
        break;
      default:
        break;
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    setFilterParams((prev) => ({
      ...prev,
      from_date: DateFormatYMD(newStartDate),
      to_date: DateFormatYMD(newEndDate),
    }));
  };

  return (
    <Flex alignItems="center" gridGap="4">
      <InputGroup bg="white">
        <InputRightElement
          pointerEvents="none"
          children={<BiCalendar />}
          color="gray.400"
        />
        <ReactDatePicker
          placeholderText={strings.select_date}
          dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
          customInput={
            <CustomDateInput _placeholder={{ color: 'black' }} minW="200px" />
          }
          onChange={onChange}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          value={getFormattedRangeDate(startDate, endDate)}
          shouldCloseOnSelect={false}
          autoComplete="off"
          showMonthDropdown
          monthsShown={1}
          showYearDropdown
          maxDate={new Date()}
          isClearable
        />
      </InputGroup>

      <Select
        placeholder={strings.select}
        textTransform="capitalize"
        bgColor="white"
        value={selectedOption}
        onChange={handleSelectChange}>
        {SELECT_OPTIONS.map((option) => (
          <option key={option} value={option}>
            {strings[option]}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default BookingDashboardSearch;
