import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import BookingDashboardResource from 'api/booking_dashboard';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  BookingDashboardFilterParams,
  NightCountSummary,
} from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { cleanData } from 'utils';

type Props = {
  filterParams: BookingDashboardFilterParams;
};

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload || payload.length === 0) return null;
  const value = payload[0];
  return (
    <Flex flexDir="column" bg="white" p={2} rounded={4} fontSize={12}>
      <Text color="black">
        {strings.total_booking_nights}: {value.payload.total_nights}
      </Text>
      <Text>
        {strings.total_booking_count}: {value.payload.count}
      </Text>
    </Flex>
  );
};

const RenderContent: React.FC<Props> = ({ filterParams }) => {
  const bookingDashboardAPI = new BookingDashboardResource();

  const { data: customerBookingNight, isLoading } = useQuery<
    NightCountSummary[]
  >({
    queryKey: ['customer-booking-night-count', filterParams],
    queryFn: async () => {
      const response = await bookingDashboardAPI.bookingNightCount(
        cleanData(filterParams)
      );
      return response.data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" minH={250}>
        <Spinner />
      </Flex>
    );
  }
  if (!customerBookingNight || customerBookingNight?.length === 0) {
    return (
      <Flex justifyContent="center" p={2} h="full" alignItems="center">
        <Text textTransform="capitalize">{strings.no_data_found}</Text>
      </Flex>
    );
  }

  return (
    <Flex display="flex" flexDir="column" alignItems="center">
      <ResponsiveContainer height={300}>
        <BarChart data={customerBookingNight}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="total_nights" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill="rgba(150, 45, 255, 1)" radius={4} />
        </BarChart>
      </ResponsiveContainer>
      <Text color="black">
        {strings.duration_of_individual_bookings_description}
      </Text>
    </Flex>
  );
};

const BookingNightSummary: React.FC<Props> = ({ filterParams }) => {
  return (
    <Stack sx={wrapperStyles} h="full">
      <Box pb={4}>
        <Heading size="md" textTransform="capitalize" pb={1}>
          {strings.duration_of_individual_bookings}
        </Heading>
        <Text color="blackAlpha.500">
          {strings.duration_of_individual_bookings_description}
        </Text>
      </Box>
      <RenderContent filterParams={filterParams} />
    </Stack>
  );
};

export default BookingNightSummary;
