/**
 * The base path is used for active link style in the sub menu.
 * Please add a base path if there are multiple sub routes under that submenu.
 */
const routes = {
  dashboard: '/',
  splash: '/splash',
  secondScreen: '/second-screen',
  profile: {
    base: '/profile/*',
    myProfile: '/profile/my-profile',
    edit: '/profile/edit',
  },
  users: {
    base: '/users/info/*',
    list: '/users/info/list',
    add: '/users/info/add',
    edit: '/users/info/edit/:id',
    profile: '/users/info/profile/:id',
    roles: {
      base: '/users/roles/*',
      list: '/users/roles/list',
      view: '/users/roles/:id',
    },
    externalCompanies: {
      base: '/users/externalCompanies/*',
      list: '/users/externalCompanies/list',
      add: '/users/externalCompanies/add',
      edit: '/users/externalCompanies/edit/:id',
      profile: '/users/externalCompanies/profile/:id',
    },
    loginLog: {
      base: '/users/login-log/*',
      list: '/users/login-log/list',
    },
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
  },
  chat: {
    default: '/chat/app',
    closed: {
      base: '/chat/closed/*',
      list: '/chat/closed/list',
      details: '/chat/closed/details/:id',
    },
    archived: {
      base: '/chat/archived/*',
      list: '/chat/archived/list',
      details: '/chat/archived/details/:id',
    },
    metrics: '/chat/metrics',
  },
  ticket: {
    task: {
      base: '/ticket/task/*',
      board: '/ticket/task/board',
      create: '/ticket/task/create',
      edit: '/ticket/task/edit/:id',
      details: '/ticket/task/details',
      view: '/ticket/task/view/:id',
    },
    list: {
      base: '/ticket/list/*',
      create: '/ticket/list/create',
      edit: '/ticket/list/edit/:id',
      details: '/ticket/list/details',
      view: '/ticket/list/view/:id',
      default: '/ticket/list/all',
    },
    objectsInfo: '/ticket/objectsInfo',
  },
  crm: {
    contact: {
      base: '/crm/contact/*',
      list: '/crm/contact/list',
      add: '/crm/contact/add/',
      edit: '/crm/contact/edit/:id',
      details: '/crm/contact/details/:id',
    },
    merge: {
      base: '/crm/merge/*',
      list: '/crm/merge/list',
    },
  },
  mms: {
    transferItems: '/mms/transfer-items',
    inventory: {
      base: '/mms/inventory/*',
      list: '/mms/inventory/list',
      details: '/mms/inventory/details/:id',
    },
    articles: {
      base: '/mms/articles/*',
      list: '/mms/articles/list',
      add: '/mms/articles/add',
      edit: '/mms/articles/edit/:id',
      details: '/mms/articles/details/:id',
    },
    objects: {
      base: '/mms/objects/*',
      list: '/mms/objects/list',
      details: '/mms/objects/details',
    },
    warehouse: {
      base: '/mms/warehouse/*',
      list: '/mms/warehouse/list',
      add: '/mms/warehouse/add',
      edit: '/mms/warehouse/edit/:id',
      view: '/mms/warehouse/view/:id',
    },
    types: {
      base: '/mms/types/*',
      list: '/mms/types/list',
      add: '/mms/types/add',
      details: '/mms/types/view/:id',
      edit: '/mms/types/edit/:id',
    },
    units: {
      base: '/mms/units/*',
      list: '/mms/units/list',
      add: '/mms/units/add',
      details: '/mms/units/view/:id',
      edit: '/mms/units/:id',
    },
    transferLogs: '/mms/transfer-logs',
    stocktaking: {
      base: '/mms/stocktaking/*',
      stocktakingMode: '/mms/stocktaking/stocktakingMode',
      correctInventory: '/mms/stocktaking/correctInventory',
    },
    myLogs: {
      base: '/mms/my-logs/*',
      list: '/mms/my-logs/list',
    },
    myInventory: {
      base: '/mms/my-inventory/*',
      list: '/mms/my-inventory/list',
    },
    qrCode: {
      base: '/mms/qrcode/*',
      list: '/mms/qrcode/list',
    },
  },
  mdm: {
    uploads: {
      list: '/mdm/uploads/list',
    },
    contacts: {
      list: '/mdm/contacts/list',
    },
    // objects: {
    //   list: '/mdm/objects/list',
    // },
    bookings: {
      list: '/mdm/bookings/list',
    },
    invoices: {
      list: '/mdm/invoices/list',
    },
    services: {
      list: '/mdm/services/list',
    },
    newsletter: {
      list: '/mdm/newsletter/list',
    },
    imports: {
      list: '/mdm/imports/list',
    },
    logs: {
      list: '/mdm/logs/list',
    },
  },
  oms: {
    objects: {
      base: '/oms/objects/*',
      list: '/oms/objects/list',
      details: '/oms/objects/details/:id',
    },
  },
  sms: {
    services: {
      base: '/sms/services/*',
      list: '/sms/services/list',
      add: '/sms/services/add',
      edit: '/sms/services/edit/:id',
      details: '/sms/services/details/:id',
    },
    serviceGroups: {
      base: '/sms/service-groups/*',
      list: '/sms/service-groups/list',
      add: '/sms/service-groups/add',
      edit: '/sms/service-groups/edit/:id',
      details: '/sms/service-groups/details/:id',
    },
    meerSylt: {
      base: '/sms/meer-sylt/*',
      list: '/sms/meer-sylt/list',
      add: '/sms/meer-sylt/add',
      edit: '/sms/meer-sylt/edit/:id',
      details: '/sms/meer-sylt/details/:id',
    },
    rabatt: {
      base: '/sms/rabatt/*',
      list: '/sms/rabatt/list',
      add: '/sms/rabatt/add',
      edit: '/sms/rabatt/edit/:id',
      details: '/sms/rabatt/details/:id',
    },
  },
  bms: {
    dashboard: {
      base: '/bms/dashboard/*',
      list: '/bms/dashboard/list',
    },
    reservation: {
      base: '/bms/reservation/*',
      list: '/bms/reservation/list',
      add: '/bms/reservation/add',
      edit: '/bms/reservation/edit/:id',
      details: '/bms/reservation/details/:id',
    },
    booking: {
      base: '/bms/booking/*',
      list: '/bms/booking/list',
      add: '/bms/booking/add',
      edit: '/bms/booking/edit/:id',
      details: '/bms/booking/details/:id',
    },
    reservationRequest: {
      base: '/bms/reservation-requests/*',
      list: '/bms/reservation-requests/list',
      details: '/bms/reservation-requests/details/:id',
    },
    ferryBooking: {
      base: '/bms/ferry-bookings/*',
      list: '/bms/ferry-bookings/list',
      details: '/bms/ferry-bookings/details/:id',
    },
    callback: {
      base: '/bms/callback/*',
      list: '/bms/callback/list',
      details: '/bms/callback/details/:id',
    },
    checkInOut: {
      base: '/bms/check-in-out/*',
      list: '/bms/check-in-out/list',
      details: '/bms/check-in-out/details/:id',
    },
    marketingEmail: {
      base: '/bms/marketing-email/*',
      list: '/bms/marketing-email/list',
    },
    marketingEmailSettings: {
      base: '/bms/settings/marketing-email/*',
      list: '/bms/settings/marketing-email',
    },
  },
  thirdPartyPartner: {
    membershipLevel: {
      base: '/third-party-partner/member-level/*',
      list: '/third-party-partner/member-level/',
      add: '/third-party-partner/member-level/add/',
      edit: '/third-party-partner/member-level/edit/:id',
      details: '/third-party-partner/member-level/details/:id',
    },
    thirdPartyPartner: {
      base: '/third-party-partner/third_party_partner/*',
      list: '/third-party-partner/third_party_partner/',
      add: '/third-party-partner/third_party_partner/add/',
      edit: '/third-party-partner/third_party_partner/edit/:id',
      details: '/third-party-partner/third_party_partner/details/:id',
    },
  },
  bs: {
    breadBooking: {
      base: '/bs/bread-bookings/*',
      list: '/bs/bread-bookings/list',
      add: '/bs/bread-bookings/add',
      edit: '/bs/bread-bookings/edit/:id',
      details: '/bs/bread-bookings/details/:id',
    },
    drivingOrder: {
      base: '/bs/driving-orders/*',
      list: '/bs/driving-orders/list',
    },
    breadDailyView: {
      base: '/bs/bread-daily-view/*',
      list: '/bs/bread-daily-view/list',
      details: '/bs/bread-daily-view/details/:id',
    },
    breadBookingBilling: {
      base: '/bs/bread-booking-billing/*',
      list: '/bs/bread-booking-billing/list',
      details: '/bs/bread-booking-billing/details/:id',
      edit: '/bs/bread-booking-billing/edit/:id',
    },
  },
  faq: {
    faqList: {
      base: '/faq/faq-list/*',
      list: '/faq/faq-list/list',
      add: '/faq/faq-list/add',
      edit: '/faq/faq-list/edit/:id',
    },
    faqGroup: {
      base: '/faq/faq-group/*',
      list: '/faq/faq-group/list',
      add: '/faq/faq-group/add',
      edit: '/faq/faq-group/edit/:id',
    },
    internalfaq: {
      base: '/faq/internal-faq/*',
      list: '/faq/internal-faq/list',
    },
  },
  dms: {
    generalList: {
      base: '/dms/generalList/*',
      list: '/dms/generalList/list',
      upload: '/dms/generalList/upload',
      edit: '/dms/generalList/edit/:id',
    },
    objectDocuments: {
      base: '/dms/objectDocuments/*',
      list: '/dms/objectDocuments/list',
      upload: '/dms/objectDocuments/upload',
      edit: '/dms/objectDocuments/edit/:id',
      objects: {
        list: '/dms/objectDocuments/objects/list/:id',
        upload: '/dms/objectDocuments/objects/upload/:id',
      },
    },
    ownerDocuments: {
      base: '/dms/ownerDocuments/*',
      list: '/dms/ownerDocuments/list',
      upload: '/dms/ownerDocuments/upload',
      edit: '/dms/ownerDocuments/edit/:id',
      owner: {
        list: '/dms/ownerDocuments/owner/list/:id',
        upload: '/dms/ownerDocuments/owner/upload/:id',
      },
    },
    customerDocuments: {
      base: '/dms/customerDocuments/*',
      list: '/dms/customerDocuments/list',
      upload: '/dms/customerDocuments/upload',
      edit: '/dms/customerDocuments/edit/:id',
      customer: {
        list: '/dms/customerDocuments/customer/list/:id',
        upload: '/dms/customerDocuments/customer/upload/:id',
      },
    },
    userDocuments: {
      base: '/dms/userDocuments/*',
      list: '/dms/userDocuments/list',
      upload: '/dms/userDocuments/upload',
      edit: '/dms/userDocuments/edit/:id',
      user: {
        list: '/dms/userDocuments/user/list/:id',
        upload: '/dms/userDocuments/user/upload/:id',
      },
    },
  },
  pms: {
    creditCard: {
      list: '/pms/credit-card/list',
    },
    paypal: {
      list: '/pms/paypal/list',
    },
    invoice: {
      list: '/pms/invoice/list',
    },
    paymentLinks: {
      base: '/pms/paymentLinks/*',
      list: '/pms/paymentLinks/list',
      add: '/pms/paymentLinks/add',
    },
  },
  pns: {
    notification: {
      base: '/pns/push-notification/*',
      list: '/pns/push-notification/list',
      add: '/pns/push-notification/add',
    },
  },
  contactCenter: {
    members: {
      base: '/contact-center/members/*',
      list: '/contact-center/members/list',
      profile: '/contact-center/members/profile/:id',
    },
    totalCalls: {
      list: '/contact-center/totalCalls/list',
    },
  },
  feedback: {
    dashboard: '/feedback/dashboard',
    analysis: '/feedback/analysis',
    feedbacks: {
      base: '/feedback/feedbacks/*',
      list: '/feedback/feedbacks/list',
      details: '/feedback/feedbacks/details/:id',
    },
    mails: {
      base: '/feedback/mails/*',
      list: '/feedback/mails/list',
    },
    questions: {
      base: '/feedback/questions/*',
      list: '/feedback/questions/list',
      add: '/feedback/questions/add',
      edit: '/feedback/questions/edit/:id',
    },
    setting: {
      base: '/feedback/setting/*',
      list: '/feedback/setting/list',
    },
  },
  newsletter: {
    newsletters: {
      base: '/newsletter/newsletters/*',
      list: '/newsletter/newsletters/list',
      view: '/newsletter/newsletters/view/:id',
      add: '/newsletter/newsletters/add',
      edit: '/newsletter/newsletters/edit/:id',
    },
    draft: {
      list: '/newsletter/draft/list',
    },
  },
  key: {
    keyList: {
      base: '/key/keyList/*',
      list: '/key/keyList/list',
      view: '/key/keyList/view/:id',
      add: '/key/keyList/add',
      edit: '/key/keyList/edit/:id',
    },
    KeyLogs: {
      base: '/key/KeyLogs/*',
      list: '/key/KeyLogs/list',
      view: '/key/KeyLogs/view/:id',
    },
  },
  objectDashboard: {
    dashboard: {
      base: '/object-dashboard/*/list/*',
      contactList: '/object-dashboard/contact/list',
      board: '/object-dashboard/:dashboard/board/:id',
      objectBoard: '/object-dashboard/object/board/:id',
      customerBoard: '/object-dashboard/customer/board/:id',
    },
    crossSelling: {
      base: '/object-dashboard/cross-selling/*',
      list: '/object-dashboard/cross-selling/list',
      add: '/object-dashboard/cross-selling/add',
      edit: '/object-dashboard/cross-selling/edit/:id',
    },
  },
  restaurant: {
    restaurant: {
      base: '/restaurant/restaurant/*',
      list: '/restaurant/restaurant/list',
      add: '/restaurant/restaurant/add',
      edit: '/restaurant/restaurant/edit/:id',
      details: '/restaurant/restaurant/details/:id',
    },
    restaurantReservation: {
      base: '/restaurant/restaurant_reservation/*',
      list: '/restaurant/restaurant_reservation',
      details: '/restaurant/restaurant_reservation/details/:id',
    },
  },
};

export default routes;
