import {
  DataWrapperSchema,
  TicketInfoSchema,
  TicketStatusSchema,
} from 'constants/schema';
import { InfiniteData, useQueryClient } from 'react-query';

type DroppableType = InfiniteData<DataWrapperSchema<TicketInfoSchema>>;

export const getQueryKey = (
  status: TicketStatusSchema,
  getQueryParams: (status: TicketStatusSchema) => {}
) => {
  return ['ticket', 'board', status, getQueryParams(status)];
};

export const retrieveQueryData = (
  queryClient: ReturnType<typeof useQueryClient>,
  key: {}[]
): DroppableType | undefined => {
  return queryClient.getQueryData<DroppableType>(key);
};

type SetQueryData = {
  queryClient: ReturnType<typeof useQueryClient>;
  key: {}[];
  list: TicketInfoSchema[];
  meta: {
    total: number;
    to: number;
  };
  isSource: boolean;
};

export const setQueryData = ({
  queryClient,
  key,
  list,
  meta,
  isSource,
}: SetQueryData): void => {
  queryClient.setQueryData<DroppableType>(key, (old: any) => {
    if (!old) return old;
    return {
      ...old,
      pages: [
        {
          ...old.pages[0],
          data: list,
          meta: {
            ...meta,
            total: isSource ? Math.max(0, meta.total - 1) : meta.total + 1,
            to: isSource ? Math.max(0, meta.to - 1) : meta.to + 1,
          },
        },
      ],
    };
  });
};

export const getStatusValue = (status: TicketStatusSchema) => {
  return status === 'inProgress' ? 'in-progress' : status;
};
