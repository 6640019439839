import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactListExportCSVButton from 'components/crm/ContactListExportCSVButton';
import ContactListItem from 'components/crm/ContactListItem';
import ContactSearch from 'components/crm/ContactSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { ContactSchema, DataWrapperSchema } from 'constants/schema';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import {
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

export interface ContactListFilterParams {
  currentPage: number;
  pageSize: number;
  first_name: string | null;
  last_name: string | null;
  location: string | null;
  street: string | null;
  type: string | null;
  source: string | null;
  updated_at_from: Date | null;
  updated_at_to: Date | null;
  is_merged: string | null;
  email: string | null;
  phone: string | null;
  mobile: string | null;
  newsletter_subscribed: string | null;
  membership_level_id: number | null;
  total_amount_spent_greater: string | null;
}

const ContactList: React.FC = () => {
  const crmAPI = new CRMContactsResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const { isOpen: isRevenueVisible, onToggle: toggleRevenue } = useDisclosure();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];
  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const updated_at_from = Number(searchValues.updated_at_from);
  const updated_at_to = Number(searchValues.updated_at_to);

  const [filterParams, setFilterParams] = useState<ContactListFilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    first_name: searchValues.first_name ?? null,
    last_name: searchValues.last_name ?? null,
    location: searchValues.location ?? null,
    street: searchValues.street ?? null,
    type: searchValues.type ?? null,
    source: searchValues.source ?? null,
    updated_at_from: updated_at_from ? new Date(updated_at_from) : null,
    updated_at_to: updated_at_to ? new Date(updated_at_to) : null,
    is_merged: searchValues.is_merged ?? null,
    email: searchValues.email ?? null,
    phone: searchValues.phone ?? null,
    mobile: searchValues.mobile ?? null,
    newsletter_subscribed: searchValues.newsletter_subscribed ?? null,
    membership_level_id: searchValues.membership_level_id ?? null,
    total_amount_spent_greater: searchValues.total_amount_spent_greater ?? null,
  });

  const filterKey: Record<string, string | null | number | Date> = useMemo(
    () => ({
      page: filterParams.currentPage,
      limit: filterParams.pageSize,
      first_name: filterParams.first_name,
      last_name: filterParams.last_name,
      location: filterParams.location,
      street: filterParams.street,
      type: filterParams.type,
      source: filterParams.source,
      updated_at_from: filterParams.updated_at_from,
      updated_at_to: filterParams.updated_at_to,
      is_merged: filterParams.is_merged,
      email: filterParams.email,
      phone: filterParams.phone,
      mobile: filterParams.mobile,
      newsletter_subscribed: filterParams.newsletter_subscribed,
      membership_level_id: filterParams.membership_level_id,
      total_amount_spent_greater: filterParams.total_amount_spent_greater,
    }),
    [filterParams]
  );

  const contactList = useQuery<DataWrapperSchema<ContactSchema[]>>(
    ['list-crm-contacts', filterKey],
    () => {
      const {
        first_name,
        last_name,
        location,
        source,
        street,
        type,
        updated_at_from,
        updated_at_to,
        is_merged,
        email,
        phone,
        mobile,
        newsletter_subscribed,
        membership_level_id,
        total_amount_spent_greater,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (first_name) queryParams.first_name = first_name;
      if (last_name) queryParams.last_name = last_name;
      if (location) queryParams.location = location;
      if (street) queryParams.street = street;
      if (type) queryParams.type = type;
      if (source) queryParams.source = source;
      if (updated_at_from)
        queryParams.updated_at_from =
          getUTCDateRangeStartDateTime(updated_at_from);
      if (updated_at_to)
        queryParams.updated_at_to = getUTCDateRangeEndDateTime(updated_at_to);
      if (is_merged) queryParams.is_merged = is_merged;
      if (email) queryParams.email = email;
      if (phone) queryParams.phone = phone;
      if (newsletter_subscribed)
        queryParams.newsletter_subscribed = newsletter_subscribed;
      if (mobile) queryParams.mobile = mobile;
      if (membership_level_id)
        queryParams.membership_level_id = membership_level_id;
      if (total_amount_spent_greater) {
        queryParams.total_amount_spent_greater = total_amount_spent_greater;
      }
      return crmAPI.list(queryParams).then((res) => {
        return res.data.data;
      });
    }
  );

  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      first_name: data.first_name,
      last_name: data.last_name,
      location: data.location,
      street: data.street,
      type: data.type,
      source: data.source,
      updated_at_from: data.updated_at_from,
      updated_at_to: data.updated_at_to,
      is_merged: data.is_merged,
      email: data.email,
      phone: data.phone,
      mobile: data.mobile,
      newsletter_subscribed: data.newsletter_subscribed,
      membership_level_id: data.membership_level_id,
      total_amount_spent_greater: data.total_amount_spent_greater,
    }));
  }, []);

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.updated_at_from) {
      data.updated_at_from = Date.parse(data.updated_at_from);
    }
    if (data.updated_at_to) {
      data.updated_at_to = Date.parse(data.updated_at_to);
    }
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.crm} | {strings.all_contacts}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.crm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.all_contacts}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.contacts}
        </Heading>

        <Flex alignItems="center" gridGap="4">
          <ContactListExportCSVButton
            filterParams={filterParams}
            filterKey={filterKey}
          />
          <RouterLink to={routes.crm.contact.add}>
            <Button colorScheme="primary" size="sm">
              {strings.add_contact}
            </Button>
          </RouterLink>
        </Flex>
      </Flex>
      <Accordion
        bg="white"
        borderColor="white"
        allowToggle
        defaultIndex={[defaultIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <ContactSearch
              filterParams={filterParams}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.label}</Th>
                <Th>{strings.source}</Th>
                <Th>{strings.membership_level}</Th>
                <Th>
                  <Flex alignItems="center" gridGap={2}>
                    {strings.revenue} (&euro;)
                    <Tooltip
                      hasArrow
                      label={isRevenueVisible ? strings.hide : strings.show}
                      size="sm">
                      <IconButton
                        size="sm"
                        variant="unstyled"
                        aria-label="Hide/show revenue"
                        icon={isRevenueVisible ? <FaRegEyeSlash /> : <FaEye />}
                        onClick={() => toggleRevenue()}
                      />
                    </Tooltip>
                  </Flex>
                </Th>
                <Th>{strings.added}</Th>
                <Th>{strings.last_activities}</Th>
                <Th>{strings.last_ticket}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactList.data?.data.map((contactData, index) => (
                <ContactListItem
                  key={contactData.id}
                  index={startingSN + index}
                  contactData={contactData}
                  search={search}
                  isRevenueVisible={isRevenueVisible}
                />
              ))}
              {contactList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={10} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={contactList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default ContactList;
