import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import ContactForm from 'components/crm/ContactForm';
import ContactFormMembership from 'components/crm/ContactFormMembership';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { cleanData } from 'utils';
import history from 'utils/history';

const APP = 'app';

const AddContact: React.FC = () => {
  const { id: contactID }: any = useParams();
  const { id: userID } = useSelector((state: any) => state.data.auth.user);
  const { search } = useLocation();

  const methods = useForm<any>();
  const toast = useToast();
  let contactAPI = new CRMContactsResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: contactDetail } = useQuery(
    ['contact-details-page', contactID],
    () => {
      if (!contactID) return null; // { data: null };
      return contactAPI
        .get(contactID)
        .then((res) => res.data.data)
        .catch();
    }
  );
  const createContact = useMutation((data: any) => contactAPI.store(data), {
    onSuccess: (res: any) => {
      const id = res?.data?.data?.id;
      history.push(routes.crm.contact.details.replace(':id', id.toString()));
    },
  });
  const updateContact = useMutation(
    (data: any) => contactAPI.update(contactID, data),
    {
      onSuccess: () => {
        history.push(
          routes.crm.contact.details.replace(':id', contactID.toString())
        );
      },
    }
  );

  const onSubmit = async (formData: any) => {
    let API;
    let data;
    if (contactID) {
      formData.updated_by = userID;
      API = updateContact;
      data = { ...formData };
    } else {
      formData.source = 'manual';
      formData.created_by = userID;
      API = createContact;
      data = cleanData(formData);
    }
    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.contact} ${
            contactID ? strings.has_been_updated : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(
          `${strings.contact} ${
            contactID
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  const displayMembershipForm = !!contactID && contactDetail?.type === APP;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.crm} | &nbsp;
            {contactID ? strings.edit_contact : strings.add_contact}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
              {strings.crm}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.crm.contact.list + search}>
              {strings.contact_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {contactID
                ? contactDetail?.first_name + ' ' + contactDetail?.last_name
                : strings.add_contact}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {contactID ? strings.edit_contact : strings.add_contact}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}
                <ContactForm data={contactDetail} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!contactID ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
        {displayMembershipForm && (
          <ContactFormMembership data={contactDetail} />
        )}
      </Stack>
    </>
  );
};

export default AddContact;
