import { Breadcrumb } from '@chakra-ui/breadcrumb';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import BookingDashboardSearch from 'components/bms_dashboard/BookingDashboardSearch';
import BookingNightSummary from 'components/bms_dashboard/BookingNightSummary';
import BookingSummary from 'components/bms_dashboard/BookingSummary';
import CustomerBooking from 'components/bms_dashboard/CustomerBooking';
import RevenueSummaryChart from 'components/bms_dashboard/RevenueSummaryChart';
import TotalNightChart from 'components/bms_dashboard/TotalNightChart';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { BookingDashboardFilterParams } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL } from 'utils';

const BookingDashboard = () => {
  const { search } = useLocation();
  const history = useHistory();
  let searchParams = new URLSearchParams(search);

  const searchFromData = searchParams.get('from_date')
    ? (searchParams.get('from_date') as string)
    : '';
  const searchToData = searchParams.get('to_date')
    ? (searchParams.get('to_date') as string)
    : '';

  const [filterParams, setFilterParams] =
    useState<BookingDashboardFilterParams>({
      from_date: searchFromData,
      to_date: searchToData,
    });

  useEffect(() => {
    const searchURL = changeURL(filterParams);
    history.push(`?${searchURL}`);
  }, [filterParams, history]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.booking} | {strings.dashboard}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.bms.dashboard.list}>
            {strings.dashboard}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.dashboard}
        </Heading>
        <BookingDashboardSearch
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Flex>

      <Grid
        gridTemplateColumns={{
          md: 'repeat(1,1fr)',
          lg: 'repeat(2,1fr)',
        }}
        gap={8}>
        <GridItem>
          <BookingSummary filterParams={filterParams} />
        </GridItem>
        <GridItem>
          <CustomerBooking filterParams={filterParams} />
        </GridItem>
      </Grid>
      <Grid
        gridTemplateColumns={{
          md: 'repeat(1,1fr)',
          lg: 'repeat(3,1fr)',
        }}
        gap={8}>
        <GridItem>
          <TotalNightChart filterParams={filterParams} />
        </GridItem>
        <GridItem>
          <BookingNightSummary filterParams={filterParams} />
        </GridItem>
        <GridItem>
          <RevenueSummaryChart filterParams={filterParams} />
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default BookingDashboard;
