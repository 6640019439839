import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import MembershipLevelResource from 'api/membership-level';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  MembershipLevelSchema,
  MembershipLevelUpdatePayload,
} from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const getSwitchChangeModalTitle = (name: string, checked: boolean) => {
  if (name === 'is_internal') {
    return checked ? strings.mark_as_internal : strings.remove_from_internal;
  }
  return checked ? strings.publish : strings.un_publish;
};

const getInputChangeModalTitle = (name: string) => {
  if (name === 'night_spent') {
    return strings.update_night_spent;
  }
  if (name === 'euro_spent_in_cents') {
    return strings.update_amount_spent;
  }
  if (name === 'number_of_booking') {
    return strings.update_number_of_bookings;
  }
  return '';
};

interface Props {
  membershipLevelData: MembershipLevelSchema;
  index: number;
  isDisabled?: boolean;
  queryKey: [string, Record<string, string | number>];
}

const MembershipLevelListItem: React.FC<Props> = (props) => {
  const { membershipLevelData, index, isDisabled, queryKey } = props;
  const {
    id,
    name,
    is_internal,
    number_of_booking,
    euro_spent_in_cents,
    night_spent,
  } = membershipLevelData;

  const euroSpent = useMemo(() => {
    if (!euro_spent_in_cents) return 0;
    return Number(Number(euro_spent_in_cents / 100).toFixed(2));
  }, [euro_spent_in_cents]);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id,
    data: membershipLevelData,
    disabled: isDisabled,
  });

  const [titleMsg, setTitleMsg] = useState('');

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isUpdateModalOpen,
    onOpen: openUpdateModal,
    onClose: closeUpdateModal,
  } = useDisclosure();

  const form = useForm<MembershipLevelUpdatePayload>({
    defaultValues: {
      euro_spent_in_cents: euroSpent,
      night_spent: night_spent || 0,
      is_internal: is_internal || false,
      number_of_booking: number_of_booking || 0,
    },
  });

  const queryClient = useQueryClient();
  const toast = useToast();
  const membershipLevelAPI = new MembershipLevelResource();

  const deleteMembershipLevel = useMutation(
    () => membershipLevelAPI.destroy(id),
    {
      onSuccess: () => {
        closeDeleteModal();
        queryClient.invalidateQueries({ queryKey });
        toast({
          title: strings.membership_delete,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const updateMembershipLevel = useMutation(
    (data: MembershipLevelUpdatePayload) =>
      membershipLevelAPI.update(id, {
        ...data,
        _method: 'PUT',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey });
        closeUpdateModal();
        toast({
          title: strings.updated,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onDeleteConfirm = () => {
    deleteMembershipLevel.mutate();
  };

  const handleSwitchUpdate: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { name, checked } = event.currentTarget;
    form.setValue(name as keyof MembershipLevelUpdatePayload, checked);
    setTitleMsg(getSwitchChangeModalTitle(name, checked));
    openUpdateModal();
  };

  const handleBookingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, valueAsNumber, value } = event.currentTarget;
    setTitleMsg(getInputChangeModalTitle(name));
    form.setValue(name as keyof MembershipLevelUpdatePayload, valueAsNumber);
    openUpdateModal();
  };

  const onUpdate = () => {
    const updateValues = form.getValues();
    const payload: MembershipLevelUpdatePayload = {
      ...updateValues,
      euro_spent_in_cents: updateValues?.euro_spent_in_cents
        ? updateValues?.euro_spent_in_cents * 100
        : 0,
    };
    updateMembershipLevel.mutate(payload);
  };
  const handleCloseModal = () => {
    form.reset();
    closeUpdateModal();
  };

  return (
    <Tr
      key={id}
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      withOpacity={isDragging}
      zIndex={isDragging ? 1 : 0}
      opacity={isDragging ? 0.5 : 1}
      cursor={isDisabled ? 'wait' : 'grab'}
      {...attributes}
      {...listeners}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            id.toString()
          )}>
          <Text
            fontWeight="medium"
            color="gray.600"
            _hover={{ color: 'gray.900' }}>
            {name ?? '-'}
          </Text>
        </RouterLink>
      </Td>

      <Td>
        <Controller
          control={form.control}
          name="number_of_booking"
          render={({ field: { value, name, ref } }) => (
            <Input
              ref={ref}
              name={name}
              type="number"
              min={0}
              defaultValue={value}
              onBlur={handleBookingChange}
              maxWidth="100"
              borderColor="gray.100"
            />
          )}
        />
      </Td>
      <Td>
        <Controller
          control={form.control}
          name="night_spent"
          render={({ field: { value, name, ref } }) => (
            <Input
              ref={ref}
              name={name}
              type="number"
              min={0}
              defaultValue={value}
              onBlur={handleBookingChange}
              maxWidth="100"
              borderColor="gray.100"
            />
          )}
        />
      </Td>
      <Td>
        <Controller
          control={form.control}
          name="euro_spent_in_cents"
          render={({ field: { value, name, ref } }) => (
            <InputGroup>
              <InputLeftElement>&euro;</InputLeftElement>
              <Input
                ref={ref}
                name={name}
                type="number"
                min={0}
                step="0.01"
                defaultValue={value}
                onBlur={handleBookingChange}
                maxWidth="100"
                borderColor="gray.100"
              />
            </InputGroup>
          )}
        />
      </Td>
      <Td>
        <Controller
          control={form.control}
          name="is_internal"
          render={({ field: { value, name, ref } }) => (
            <Switch
              ref={ref}
              name={name}
              size="sm"
              colorScheme="primary"
              isChecked={value}
              onChange={handleSwitchUpdate}
            />
          )}
        />
      </Td>

      <Td>
        <RouterLink
          to={routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            id.toString()
          )}>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <RouterLink
          to={routes.thirdPartyPartner.membershipLevel.edit.replace(
            ':id',
            id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_member_level}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_member_level}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={openDeleteModal}
          />
        </Tooltip>
        <Modal isOpen={isDeleteModalOpen} isCentered onClose={closeDeleteModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.confirm_delete}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteMembershipLevel.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={closeDeleteModal}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isUpdateModalOpen} isCentered onClose={closeUpdateModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.update}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.formatString(
                strings.message_update_membership_value,
                titleMsg
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="green"
                  onClick={onUpdate}
                  isLoading={updateMembershipLevel.isLoading}>
                  {strings.update}
                </Button>

                <Button variant="outline" onClick={handleCloseModal}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default MembershipLevelListItem;
