import { Flex, Heading, Stack } from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RabattAPI from 'api/rabatt';
import { wrapperStyles } from 'assets/css/commonStyles';
import DebounceInput from 'components/common/DebounceInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import RabattListItem from 'components/sms_service/RabattListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { DataWrapperSchema, Rabatt } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData, getStartingSerialNumber } from 'utils';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  code?: string;
};

type Props = {
  isLoading: boolean;
  filterParams: FilterParams;
  data: DataWrapperSchema<Rabatt[]> | undefined;
  startingSN: number;
};

const RenderTableContent: React.FC<Props> = ({
  isLoading,
  filterParams,
  data,
  startingSN,
}) => {
  if (isLoading) {
    return <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />;
  }
  if (!data || data.data.length === 0) {
    return (
      <Tr>
        <Td colSpan={7} textAlign="center">
          {strings.no_records_found}
        </Td>
      </Tr>
    );
  }
  return (
    <>
      {data.data.map((item) => (
        <RabattListItem key={item.id} startingSN={startingSN} rabatt={item} />
      ))}
    </>
  );
};

const RabattList = () => {
  const rabattAPI = new RabattAPI();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage:
      Number(searchParams.get('currentPage')) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE,
  });

  const rabattList = useQuery<DataWrapperSchema<Rabatt[]>>({
    queryKey: [
      'rabatt',
      'list',
      filterParams.code,
      filterParams.currentPage,
      filterParams.pageSize,
    ],
    queryFn: async () => {
      const searchParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        code: filterParams.code ?? '',
      } as const;

      const cleanParams = cleanData(searchParams);
      const response = await rabattAPI.list(cleanParams);
      return response.data;
    },
  });
  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    const searchUrl = changeURL(filterParams);
    history.push(`?${searchUrl}`);
  }, [filterParams, history]);

  const handleReset = () => {
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    }));
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.sms} | {strings.discount_code}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sms.rabatt.list}>
            {strings.discount_code}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.discount_code}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.discount_code}
        </Heading>
        <RouterLink to={routes.sms.rabatt.add}>
          <Button size="sm" colorScheme="primary" type="button">
            {strings.add_discount_code}
          </Button>
        </RouterLink>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Stack
          direction={['column', 'column', 'row']}
          spacing="4"
          align={['start', 'start', 'end']}>
          <FormControl>
            <FormLabel>{strings.discount_code}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <DebounceInput
                name="code"
                placeholder={strings.discount_code}
                initialValue={searchParams.get('code') ?? ''}
                setFilterParams={setFilterParams}
              />
            </InputGroup>
          </FormControl>
          <Button
            colorScheme="primary"
            type="button"
            variant="outline"
            onClick={handleReset}>
            {strings.reset_filter}
          </Button>
        </Stack>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.discount_code}</Th>
                <Th>{strings.amount} (&euro;)</Th>
                <Th>{strings.total_usage}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <RenderTableContent
                isLoading={rabattList.isLoading}
                data={rabattList.data}
                filterParams={filterParams}
                startingSN={startingSN}
              />
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={rabattList}
      />
    </Stack>
  );
};

export default RabattList;
