import { Avatar, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { CustomerBooking } from 'constants/schema';
import React from 'react';
import { CiTimer } from 'react-icons/ci';
import { FaRegMoon } from 'react-icons/fa';
import { LuBedSingle } from 'react-icons/lu';
import { TbCashBanknote } from 'react-icons/tb';
import { formatCurrency } from 'utils';

type CustomerBookingItem = {
  index: number;
  details: CustomerBooking;
};

const CustomerBookingItem: React.FC<CustomerBookingItem> = ({
  index,
  details,
}) => {
  const {
    first_name,
    middle_name,
    last_name,
    total_nights_spent,
    total_booking_count,
    total_amount_spent,
  } = details;
  const name = `${first_name} ${middle_name ?? ''} ${last_name}`;
  const averageStayCount = (total_nights_spent / total_booking_count).toFixed(
    2
  );
  const averageSpentPerNight = total_amount_spent / total_nights_spent;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={2}
      backgroundColor={index % 2 === 0 ? 'whiteAlpha.700' : 'white'}>
      <Flex gridGap="2" alignItems="center">
        <Avatar name={name} />
        <Flex flexDir="column">
          <Text fontSize={16} color="blackAlpha.800">
            {name}
          </Text>
          <Flex gridGap="4" flexWrap="wrap">
            <Tooltip label={strings.total_nights_spent}>
              <Flex alignItems="center" gridGap="2">
                <Icon as={FaRegMoon} color="orange.400" width={4} height={4} />

                <Text>{total_nights_spent}</Text>
              </Flex>
            </Tooltip>
            <Tooltip label={strings.average_stay_count}>
              <Flex alignItems="center" gridGap="2">
                <Icon as={LuBedSingle} color="red.400" width={4} height={4} />
                <Text>{averageStayCount}</Text>
              </Flex>
            </Tooltip>
            <Tooltip label={strings.average_spent_per_night}>
              <Flex alignItems="center" gridGap="2">
                <Icon
                  as={TbCashBanknote}
                  color="blue.400"
                  width={4}
                  height={4}
                />
                <Text>{formatCurrency(averageSpentPerNight / 100) ?? '-'}</Text>
              </Flex>
            </Tooltip>
            <Tooltip label={strings.total_amount_spent}>
              <Flex alignItems="center" gridGap="2">
                <Icon as={CiTimer} color="purple.400" width={4} height={4} />

                <Text>{formatCurrency(total_amount_spent / 100) ?? '-'}</Text>
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Text fontSize={16} color="blackAlpha.800">
        {total_booking_count} {strings.booking}
      </Text>
    </Flex>
  );
};

export default CustomerBookingItem;
