import { Button, ButtonGroup } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import CRMContactsResource from 'api/crm-contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  ContactInputSchema,
  CrmMembershipList,
  DataWrapperWithSuccess,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import history from 'utils/history';

const MEMBERSHIP_FILTER = {
  is_active: true,
  is_internal: true,
} as const;

type FormType = Pick<ContactInputSchema, 'manual_membership_level_id'>;

interface Props {
  data?: any;
}

const ContactFormMembership: React.FC<Props> = ({ data }) => {
  const contactAPI = new CRMContactsResource();
  const query = useQueryClient();
  const { id: contactID } = useParams<{ id: string }>();
  const { id: userID } = useSelector((state: any) => state.data.auth.user);

  const toast = useToast();

  const { data: membershipList, isLoading: isMembershipLoading } = useQuery<
    DataWrapperWithSuccess<CrmMembershipList[]>
  >({
    queryKey: ['membership-list-crm', MEMBERSHIP_FILTER],
    queryFn: async () => {
      const response = await contactAPI.membershipList(MEMBERSHIP_FILTER);
      return response.data?.data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const { mutate, isLoading } = useMutation(
    (data: any) => contactAPI.update(contactID, data),
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ['contact-details-page', contactID],
        });
        toast({
          title: strings.successfully_assigned_membership,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const { register, setValue, handleSubmit, getValues } = useForm<FormType>();

  const onSubmit = async (formData: FormType) => {
    if (formData.manual_membership_level_id === '') {
      formData.manual_membership_level_id = null;
    }
    const data = { ...formData, updated_by: userID };
    mutate(data);
  };

  useEffect(() => {
    if (data && data?.manual_membership_update) {
      setValue('manual_membership_level_id', data.membership_level_id);
    }
  }, [data, setValue, isMembershipLoading]);

  return (
    <Stack sx={wrapperStyles} spacing={4}>
      <Heading size="md" textTransform="capitalize">
        {strings.assign_membership_level}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" gridGap={4}>
          <FormControl>
            <FormLabel>{strings.current_membership_level}:</FormLabel>
            <Text>
              {data?.membershipLevel ? data.membershipLevel?.name : '-'}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>{strings.internal_membership_level}</FormLabel>
            <Select
              {...register('manual_membership_level_id')}
              placeholder={strings.default_membership_level}
              rounded="sm"
              defaultValue={data?.membership_level_id}>
              {membershipList?.data?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <ButtonGroup>
            <Button colorScheme="primary" type="submit" isLoading={isLoading}>
              {strings.assign}
            </Button>
            <Button
              variant="outline"
              isDisabled={isLoading}
              onClick={() => history.goBack()}>
              {strings.cancel}
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </Stack>
  );
};

export default ContactFormMembership;
