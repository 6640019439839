import { InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import { Icon, IconButton, Input, InputLeftElement } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import BookingDashboardResource from 'api/booking_dashboard';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  BookingDashboardFilterParams,
  CustomerBooking as CustomerBookingType,
  DataWrapperSchema,
} from 'constants/schema';
import { useDebounce } from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { CiTimer } from 'react-icons/ci';
import { FaRegMoon } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { LuBedSingle } from 'react-icons/lu';
import { TbCashBanknote } from 'react-icons/tb';
import { useInfiniteQuery } from 'react-query';
import { cleanData } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import CustomerBookingItem from './CustomerBookingItem';

type Props = {
  filterParams: BookingDashboardFilterParams;
};
type RenderContentProps = {
  keyword: string;
} & Props;
const RenderContent: React.FC<RenderContentProps> = ({
  keyword,
  filterParams,
}) => {
  const bookingDashboardApi = new BookingDashboardResource();

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery<DataWrapperSchema<CustomerBookingType[]>>({
      queryKey: ['booking-dashboard-customer-booking', filterParams, keyword],
      queryFn: async ({ pageParam = 1 }) => {
        const params = {
          limit: 30,
          page: pageParam,
          keyword,
          ...filterParams,
        };
        const response = await bookingDashboardApi.customerBookings(
          cleanData(params)
        );
        return response.data;
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage) return null;
        return lastPage.meta.current_page < lastPage.meta.last_page
          ? lastPage.meta.current_page + 1
          : null;
      },
    });

  const { observer, visible } = useIntersectionObserver(
    isLoading || isFetchingNextPage
  );

  useEffect(() => {
    if (visible) {
      fetchNextPage();
    }
  }, [visible, fetchNextPage]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" minH={250}>
        <Spinner colorScheme="primary" />
      </Flex>
    );
  }

  const customerBookingList = data?.pages?.map((item) => item?.data)?.flat();

  if (!customerBookingList || customerBookingList?.length === 0) {
    return (
      <Flex justifyContent="center" p={2}>
        <Text>{strings.no_data_found}</Text>
      </Flex>
    );
  }

  const displayObserver = !isFetchingNextPage && !isLoading && hasNextPage;

  return (
    <>
      {customerBookingList.map((item, index) => (
        <CustomerBookingItem key={index} index={index} details={item} />
      ))}
      {displayObserver && <div ref={observer}></div>}
      {isFetchingNextPage && (
        <Flex justifyContent="center" p={2}>
          <Spinner colorScheme="primary" />
        </Flex>
      )}
    </>
  );
};

const CustomerBooking: React.FC<Props> = ({ filterParams }) => {
  const [keyword, setKeyword] = useState('');
  const debounceKeyword = useDebounce(keyword, 800);

  return (
    <Stack sx={wrapperStyles} position="relative">
      <Flex pb={4} alignItems="center" justifyContent="space-between">
        <Box>
          <Heading size="md" textTransform="capitalize">
            {strings.customer_booking}
          </Heading>
          {filterParams.from_date && filterParams.to_date && (
            <Text color="blackAlpha.500">
              {DateFormat(filterParams.from_date)}&nbsp;-&nbsp;
              {DateFormat(filterParams.to_date)}
            </Text>
          )}
        </Box>
        <Flex>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<BiSearch />}
              color="gray.400"
            />
            <Input
              type="text"
              name="keyword"
              placeholder={strings.search}
              value={keyword}
              onChange={(e) => setKeyword(e.currentTarget.value)}
            />
            {!!keyword && (
              <InputRightElement>
                <IconButton
                  variant="unstyled"
                  aria-label="Clear search"
                  icon={<IoMdClose />}
                  onClick={() => setKeyword('')}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        gridGap="4"
        justifyContent="space-between"
        flexWrap="wrap">
        <Flex alignItems="center" gridGap="2">
          <Icon as={FaRegMoon} color="orange.400" width={4} height={4} />
          <Text>{strings.total_nights_spent}</Text>
        </Flex>
        <Flex alignItems="center" gridGap="2">
          <Icon as={LuBedSingle} color="red.400" width={4} height={4} />
          <Text>{strings.average_stay_count}</Text>
        </Flex>
        <Flex alignItems="center" gridGap="2">
          <Icon as={TbCashBanknote} color="blue.400" width={4} height={4} />
          <Text>{strings.average_spent_per_night}</Text>
        </Flex>
        <Flex alignItems="center" gridGap="2">
          <Icon as={CiTimer} color="purple.400" width={4} height={4} />
          <Text>{strings.total_amount_spent}</Text>
        </Flex>
      </Flex>
      <Flex flexDir="column" h={350} overflowY="scroll">
        <RenderContent filterParams={filterParams} keyword={debounceKeyword} />
      </Flex>
    </Stack>
  );
};

export default CustomerBooking;
