import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import BookingDashboardResource from 'api/booking_dashboard';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { BookingDashboardFilterParams, RevenueSummary } from 'constants/schema';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { cleanData } from 'utils/index';

const convertToEuro = (range: string) => {
  const [start, end] = range.split(' - ').map((val) => parseInt(val) / 100000);
  const startInEuroTimesThousand = start % 1 !== 0 ? start.toFixed(2) : start;
  const endInEuroTimesThousand = end % 1 !== 0 ? end.toFixed(2) : end;
  return `${startInEuroTimesThousand} - ${endInEuroTimesThousand} `;
};

const toolTipRange = (range: string) => {
  if (!range) return range;
  const [start, end] = range.split(' - ').map((val) => parseInt(val) * 1000);
  return `${start} - ${end}`;
};

type Props = {
  filterParams: BookingDashboardFilterParams;
};
const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload || payload.length === 0) return null;
  const value = payload[0];
  return (
    <Flex flexDir="column" bg="white" p={2} rounded={4} fontSize={12}>
      <Text color="black">{toolTipRange(value.payload?.range)}</Text>
      <Text>
        {strings.no_of_customer}:{value.payload.count}
      </Text>
    </Flex>
  );
};

const RenderContent: React.FC<Props> = ({ filterParams }) => {
  const bookingDashboardAPI = new BookingDashboardResource();

  const { data: revenueList, isLoading } = useQuery<RevenueSummary[]>({
    queryKey: ['revenue-summary', filterParams],
    queryFn: async () => {
      const response = await bookingDashboardAPI.revenueSummary(
        cleanData(filterParams)
      );
      return response.data;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const formattedData: RevenueSummary[] = useMemo(() => {
    if (!revenueList || revenueList.length === 0) return [];
    return revenueList
      .filter((item) => !!item.count)
      .map((item) => ({
        range: convertToEuro(item.range),
        count: item.count,
      }));
  }, [revenueList]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" minH={250}>
        <Spinner />
      </Flex>
    );
  }
  if (!formattedData || formattedData?.length === 0) {
    return (
      <Flex justifyContent="center" p={2} h="full" alignItems="center">
        <Text textTransform="capitalize">{strings.no_data_found}</Text>
      </Flex>
    );
  }

  return (
    <Flex display="flex" flexDir="column" alignItems="center">
      <ResponsiveContainer height={300}>
        <BarChart data={formattedData}>
          <XAxis dataKey="range" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="count" fill="rgba(74, 58, 255, 1)" radius={4} />
        </BarChart>
      </ResponsiveContainer>
      <Text color="black">{strings.spending_range}</Text>
    </Flex>
  );
};

const RevenueSummaryChart: React.FC<Props> = ({ filterParams }) => {
  return (
    <Stack sx={wrapperStyles} h="full">
      <Box pb={4}>
        <Heading size="md" textTransform="capitalize" pb={1}>
          {strings.customer_spending_overview}
        </Heading>
        <Text color="blackAlpha.500">
          {strings.customer_spending_overview_description}
        </Text>
      </Box>
      <RenderContent filterParams={filterParams} />
    </Stack>
  );
};

export default RevenueSummaryChart;
