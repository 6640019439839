import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { Switch } from '@chakra-ui/switch';
import { Td, Tr } from '@chakra-ui/table';
import { Tooltip } from '@chakra-ui/tooltip';
import RabattAPI from 'api/rabatt';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { Rabatt } from 'constants/schema';
import React from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils';

type Props = {
  rabatt: Rabatt;
  startingSN: number;
};

const RabattListItem: React.FC<Props> = ({ rabatt, startingSN }) => {
  const rabattAPI = new RabattAPI();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutate: deleteMutation, isLoading: isDeleteMutationLoading } =
    useMutation({
      mutationFn: () => rabattAPI.destroy(rabatt.id),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.successfully_deleted_discount_code,
        });
        queryClient.invalidateQueries({ queryKey: ['rabatt', 'list'] });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.failed_to_delete_discount_code,
        });
      },
    });
  const { mutate: updateMutation, isLoading: isUpdateMutationLoading } =
    useMutation({
      mutationFn: (payload: Partial<Rabatt>) =>
        rabattAPI.update(rabatt.id, payload),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.successfully_updated_discount_code,
        });
        queryClient.invalidateQueries({ queryKey: ['rabatt', 'list'] });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.failed_to_update_discount_code,
        });
      },
    });

  const onDeleteConfirm = () => {
    deleteMutation();
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateMutation(data);
  };

  return (
    <Tr>
      <Td>{startingSN}</Td>
      <Td>
        <Link
          to={routes.sms.rabatt.details.replace(':id', rabatt.id.toString())}>
          <Stack direction="row" alignItems="center">
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {rabatt.code}
            </Text>
          </Stack>
        </Link>
      </Td>
      <Td>{formatCurrency(rabatt.amount / 100)}</Td>
      <Td>{rabatt.no_of_useage}</Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="status"
            colorScheme="green"
            isChecked={!!rabatt.status}
            isDisabled={isUpdateMutationLoading}
            onChange={onStatusChange}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" alignItems="baseline">
          <Link
            to={routes.sms.rabatt.details.replace(':id', rabatt.id.toString())}>
            <Tooltip hasArrow label={strings.view}>
              <IconButton
                icon={<BiShow />}
                variant="link"
                aria-label={strings.view}
                color="green.300"
                minW="6"
              />
            </Tooltip>
          </Link>
          <Link
            to={routes.sms.rabatt.edit.replace(':id', rabatt.id.toString())}>
            <Tooltip hasArrow label={strings.edit}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit}
                color="blue.300"
                minW="6"
              />
            </Tooltip>
          </Link>
          <Tooltip hasArrow label={strings.delete}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete}
              color="red.300"
              minW="6"
              isDisabled={isDeleteMutationLoading}
              onClick={() => onOpen()}
            />
          </Tooltip>
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{strings.delete_discount_code}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {strings.are_you_sure_you_cannot_undo_this_action}
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="primary"
                  mr={3}
                  onClick={onDeleteConfirm}
                  isLoading={isDeleteMutationLoading}
                  isDisabled={isDeleteMutationLoading}>
                  {strings.delete}
                </Button>
                <Button colorScheme="primary" variant="ghost" onClick={onClose}>
                  {strings.cancel}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Td>
    </Tr>
  );
};

export default RabattListItem;
