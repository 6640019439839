import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { TableContainer, useToast } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import MembershipLevelResource from 'api/membership-level';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import MembershipLevelListItem from 'components/third_party_partner/MemberLevelListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperSchema,
  MembershipLevelSchema,
  MembershipLevelUpdateRankingPayload,
} from 'constants/schema';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import MembershipLevelListFilter from '../../../../components/third_party_partner/MembershipLevelListFilter';

export interface MembershipLevelFilterParams {
  currentPage: number;
  pageSize: number;
  name: string;
  is_active: string;
}

const MembershipList: React.FC = () => {
  const { search } = useLocation();

  const membershipLevelAPI = new MembershipLevelResource();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<MembershipLevelFilterParams>(
    {
      currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
      pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
      name: searchValues.name ?? '',
      is_active: searchValues.is_active ?? '',
    }
  );

  const queryKey = useMemo(
    () =>
      [
        'membership-level-list',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          is_active: filterParams.is_active,
          name: filterParams.name,
        },
      ] as [string, Record<string, string | number>],
    [filterParams]
  );

  const membershipLevelListData = useQuery<
    DataWrapperSchema<MembershipLevelSchema[]>
  >({
    queryKey,
    queryFn: async () => {
      const { is_active, name } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (is_active) queryParams.is_active = is_active;
      if (name) queryParams.name = name;
      const response = await membershipLevelAPI.list(queryParams);
      return response.data;
    },
  });

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>{strings.membership}</title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink>{strings.membership}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.membershipLevel.list}>
              {strings.list}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.membership_list}
          </Heading>
          <RouterLink to={routes.thirdPartyPartner.membershipLevel.add}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.add_new_member_level}
            </Button>
          </RouterLink>
        </Flex>
        <MembershipLevelListFilter
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.membership_level}</Th>
                  <Th>{strings.number_of_booking}</Th>
                  <Th>{strings.night_spent}</Th>
                  <Th>{strings.amount_spent}(&euro;)</Th>
                  <Th>{strings.internal}</Th>
                  <Th>{strings.actions}</Th>
                </Tr>
              </Thead>
              <RenderContent
                isLoading={membershipLevelListData.isLoading}
                membershipList={membershipLevelListData.data}
                startingSN={startingSN}
                queryKey={queryKey}
              />
            </Table>
          </TableContainer>
        </Stack>

        {membershipLevelListData?.data && (
          <Pagination
            dataList={membershipLevelListData}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default MembershipList;

type RenderContentProps = {
  isLoading: boolean;
  membershipList: DataWrapperSchema<MembershipLevelSchema[]> | undefined;
  startingSN: number;
  queryKey: [string, Record<string, string | number>];
};
const RenderContent = ({
  isLoading,
  membershipList,
  startingSN,
  queryKey,
}: RenderContentProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const membershipLevelAPI = new MembershipLevelResource();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor)
  );

  const [draggingRow, setDraggingRow] = useState<MembershipLevelSchema | null>(
    null
  );

  const { mutate: updateRankingMutate, isLoading: isUpdateRankingLoading } =
    useMutation({
      mutationFn: (data: MembershipLevelUpdateRankingPayload) =>
        membershipLevelAPI.updateRanking(data),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.status_changed,
        });
        queryClient.invalidateQueries({
          queryKey,
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
        });
      },
    });

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setDraggingRow(active.data.current as MembershipLevelSchema);
  }, []);

  const handleDragCancel = useCallback(() => {
    setDraggingRow(null);
  }, []);

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id === over?.id) return;
    if (!over?.id) return;
    const activeItem = active.data.current as MembershipLevelSchema;
    const overItem = over.data.current as MembershipLevelSchema;
    updateRankingMutate({
      new_serial_number: overItem.serial_number,
      old_serial_number: activeItem.serial_number,
    });
  };

  if (isLoading) {
    return <TableSkeletonLoader rows={50} cols={7} />;
  }

  if (!membershipList || membershipList?.data?.length === 0) {
    return (
      <Tbody>
        <Tr>
          <Td colSpan={8} textAlign="center">
            {strings.no_data_found}
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}>
      <SortableContext
        items={membershipList?.data?.map((item) => item) ?? []}
        strategy={rectSortingStrategy}>
        <Tbody>
          {membershipList?.data?.map((item, index: number) => (
            <MembershipLevelListItem
              key={item.id}
              index={startingSN + index}
              membershipLevelData={item}
              queryKey={queryKey}
              isDisabled={isUpdateRankingLoading || isLoading}
            />
          ))}
        </Tbody>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {draggingRow && (
          <MembershipLevelListItem
            index={1}
            queryKey={queryKey}
            membershipLevelData={draggingRow}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
