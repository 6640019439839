import { Grid, GridItem, Stack, Text } from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormLabel,
} from '@chakra-ui/react';
import RabattAPI from 'api/rabatt';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { Rabatt } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils';

const RabattDetail = () => {
  const { id } = useParams<{ id: string }>();
  const rabattAPI = new RabattAPI();

  const { data: rabattDetail, isLoading } = useQuery<Rabatt>({
    queryKey: ['rabatt', 'detail', id],
    queryFn: async () => {
      const response = await rabattAPI.get(id);
      return response.data?.data;
    },
    enabled: !!id,
  });

  if (isLoading) {
    return <CenterSpinner />;
  }

  const discountCodeName = rabattDetail?.code ?? '-';

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.sms} | {discountCodeName}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routes.sms.rabatt.list}>
            {strings.discount_code}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {discountCodeName}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack sx={wrapperStyles}>
        <Grid
          gridTemplateColumns={{
            sm: 'repeat(2,1fr)',
            lg: 'repeat(4,1fr)',
          }}
          gap="4">
          <GridItem>
            <FormLabel>{strings.discount_code}</FormLabel>
            <Text>{discountCodeName}</Text>
          </GridItem>
          <GridItem>
            <FormLabel>{strings.amount}(&euro;)</FormLabel>
            <Text>
              {rabattDetail?.amount
                ? formatCurrency(rabattDetail.amount / 100)
                : '-'}
            </Text>
          </GridItem>
          <GridItem>
            <FormLabel>{strings.total_usage}</FormLabel>
            <Text>{rabattDetail?.no_of_useage ?? 0}</Text>
          </GridItem>
          <GridItem>
            <FormLabel>{strings.status}</FormLabel>
            <Text>
              {rabattDetail?.status ? strings.active : strings.in_active}
            </Text>
          </GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default RabattDetail;
