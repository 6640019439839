import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import CRMContactsResource from 'api/crm-contacts';
import { strings } from 'config/localization';
import { ContactListFilterParams } from 'pages/dashboard/crm/ContactList';
import React from 'react';
import { useQuery } from 'react-query';
import {
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

type Props = {
  filterParams: ContactListFilterParams;
  filterKey: Record<string, string | null | number | Date>;
};

const ContactListExportCSVButton: React.FC<Props> = ({
  filterKey,
  filterParams,
}) => {
  const toast = useToast();
  const crmAPI = new CRMContactsResource();

  const { refetch, isFetching } = useQuery({
    queryKey: ['csvDownload', filterKey],
    queryFn: async () => {
      try {
        const {
          first_name,
          last_name,
          location,
          source,
          street,
          type,
          updated_at_from,
          updated_at_to,
          is_merged,
          email,
          phone,
          mobile,
          newsletter_subscribed,
          membership_level_id,
        } = filterParams;
        const queryParams: any = {};
        if (first_name) queryParams.first_name = first_name;
        if (last_name) queryParams.last_name = last_name;
        if (location) queryParams.location = location;
        if (street) queryParams.street = street;
        if (type) queryParams.type = type;
        if (source) queryParams.source = source;
        if (updated_at_from)
          queryParams.updated_at_from =
            getUTCDateRangeStartDateTime(updated_at_from);
        if (updated_at_to)
          queryParams.updated_at_to = getUTCDateRangeEndDateTime(updated_at_to);
        if (is_merged) queryParams.is_merged = is_merged;
        if (email) queryParams.email = email;
        if (phone) queryParams.phone = phone;
        if (newsletter_subscribed)
          queryParams.newsletter_subscribed = newsletter_subscribed;
        if (mobile) queryParams.mobile = mobile;
        if (membership_level_id)
          queryParams.membership_level_id = membership_level_id;

        const response = await crmAPI.exportCsv(queryParams);

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers?.['content-disposition'];
        let filename = 'contacts.csv'; // default filename
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return null;
      } catch (error) {
        toast({
          title: strings.error_boundary_heading_text,
        });
      }
    },
    enabled: false,
  });

  return (
    <Button
      colorScheme="primary"
      size="sm"
      onClick={() => refetch()}
      isLoading={isFetching}>
      Export CSV
    </Button>
  );
};

export default ContactListExportCSVButton;
