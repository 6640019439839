import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import RabattAPI from 'api/rabatt';
import { wrapperStyles } from 'assets/css/commonStyles';
import RabattForm from 'components/sms_service/RabattForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { Rabatt } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddRabatt = () => {
  const rabattAPI = new RabattAPI();
  const history = useHistory();
  const queryClient = useQueryClient();
  const toast = useToast();

  const form = useForm<Rabatt>({
    defaultValues: {
      code: '',
      status: 1,
      amount: 0,
    },
  });

  const { isLoading, mutate } = useMutation({
    mutationFn: (data: Rabatt) => rabattAPI.store(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['rabatt', 'list'] });
      toast({
        status: 'success',
        title: strings.successfully_added_new_discount_code,
      });
      history.goBack();
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.failed_to_add_discount_code,
      });
    },
  });

  const onSubmit: SubmitHandler<Rabatt> = (data) => {
    mutate(data);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.discount} | {strings.add_discount_code}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sms.rabatt.list}>
            {strings.discount}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.add_discount_code}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_discount_code}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              <RabattForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={isLoading}>
                  {strings.add_discount_code}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddRabatt;
