import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Grid, GridItem } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import { strings } from 'config/localization';
import { Rabatt } from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  formData?: Rabatt;
};

const RabattForm: React.FC<Props> = ({ formData }) => {
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Rabatt>();

  useEffect(() => {
    if (!formData) return;
    setValue('code', formData.code);
    setValue('status', formData.status);
    setValue('amount', formData.amount);
  }, [formData, setValue]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('status', e.currentTarget.checked ? 1 : 0);
  };

  return (
    <Grid
      gap={4}
      gridTemplateColumns={{
        sm: 'repeat(1,1fr)',
        lg: 'repeat(2,1fr)',
      }}>
      <GridItem>
        <FormControl isInvalid={!!errors?.code} isRequired>
          <FormLabel>{strings.discount_code}</FormLabel>
          <Input
            {...register('code', {
              required: strings.required,
            })}
            type="text"
            maxLength={20}
            placeholder={strings.discount_code}
          />
          {!!errors.code?.message && (
            <FormErrorMessage>{errors.code.message}</FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={!!errors?.amount} isRequired>
          <FormLabel>{strings.amount}(Cent)</FormLabel>
          <Input
            {...register('amount', {
              required: strings.required,
            })}
            type="number"
            placeholder={strings.number}
          />
          {!!errors.amount?.message && (
            <FormErrorMessage>{errors.amount.message}</FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel>{strings.status}</FormLabel>
          <Controller
            control={control}
            name="status"
            render={({ field: { name, value } }) => (
              <Switch
                colorScheme="primary"
                name={name}
                isChecked={!!value}
                onChange={handleSwitchChange}
              />
            )}
          />
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default RabattForm;
