import {
  Box,
  Flex,
  IconButton,
  Image,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { Merge } from 'assets/icons';
import DeleteButton from 'components/common/DeleteButton';
import LabelTags from 'components/crm/LabelTags';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { BiEdit, BiLinkExternal, BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { formatCurrency } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  contactData: ContactSchema;
  index: number;
  search: string;
  isRevenueVisible: boolean;
}

const ContactListItem: React.FC<Props> = (props) => {
  const { contactData, index, search, isRevenueVisible } = props;
  const {
    id,
    first_name,
    last_name,
    type,
    source,
    contactDetail,
    contactEvent,
    membershipLevel,
    total_amount_spent,
  } = contactData;

  const crmAPI = new CRMContactsResource();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => crmAPI.destroy(id),
    onSuccess: () => queryClient.invalidateQueries('list-crm-contacts'),
  });

  const isAppOrWebUser = source === 'app';

  const isDeletePermitted = useIsPermitted([
    PermissionRequest['delete:contacts'],
  ]);

  const hasDashboardPermission = useIsPermitted([
    PermissionRequest['read:object-dashboard'],
  ]);

  const getHiddenLabel = isRevenueVisible ? '-' : '---------';

  return (
    <Tr>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.crm.contact.details.replace(':id', id.toString()) + search
          }>
          <Flex>
            {first_name + ' ' + last_name}
            {contactDetail?.is_merged && (
              <Box w="6" h="6" ml="2" color="orange.300">
                <Image src={Merge} />
              </Box>
            )}
          </Flex>
        </RouterLink>
      </Td>
      <Td>
        <LabelTags label={type === 'app' ? 'web_app' : type} />
      </Td>
      <Td>{strings[isAppOrWebUser ? 'web_app' : source]}</Td>
      <Td>{membershipLevel ? membershipLevel.name : '-'}</Td>
      <Td>
        <Box
          userSelect={isRevenueVisible ? 'auto' : 'none'}
          filter={isRevenueVisible ? 'none' : 'blur(5px)'}>
          {total_amount_spent
            ? formatCurrency(total_amount_spent / 100)
            : getHiddenLabel}
        </Box>
      </Td>
      <Td>{DateFormat(contactDetail?.created_at)}</Td>
      <Td>-</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        {contactEvent?.event === 'ticket' && contactEvent?.event_id ? (
          <RouterLink
            to={routes.ticket.task.view.replace(
              ':id',
              contactEvent?.event_id.toString()
            )}>
            {strings.ticket} #{contactEvent?.event_id}
          </RouterLink>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        <RouterLink
          to={
            routes.crm.contact.details.replace(':id', id.toString()) + search
          }>
          <Tooltip hasArrow label={strings.view_contact}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_contact}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        {hasDashboardPermission && (
          <RouterLink
            target="_blank"
            to={routes.objectDashboard.dashboard.customerBoard.replace(
              ':id',
              id.toString()
            )}>
            <Tooltip hasArrow label={strings.dashboard}>
              <IconButton
                icon={<BiLinkExternal />}
                variant="link"
                aria-label={strings.dashboard}
                minW="6"
              />
            </Tooltip>
          </RouterLink>
        )}
        <RouterLink
          to={`${
            routes.crm.contact.edit.replace(':id', id.toString()) + search
          }`}>
          <Tooltip hasArrow label={strings.edit_contact}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_contact}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        {isDeletePermitted && !isAppOrWebUser && (
          <DeleteButton
            modalHeader={strings.delete_contact}
            isLoading={isLoading}
            onDelete={mutate}
          />
        )}
      </Td>
    </Tr>
  );
};

export default ContactListItem;
