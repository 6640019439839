import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button, IconButton } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Heading, Stack } from '@chakra-ui/layout';
import {
  InputGroup,
  InputLeftElement,
  TableContainer,
  Text,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Tooltip } from '@chakra-ui/tooltip';
import ServiceReservationResource from 'api/service-reservation';
import { selectColorScheme, wrapperStyles } from 'assets/css/commonStyles';
import DebounceInput from 'components/common/DebounceInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch, BiShow } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber, truncateString } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  status: string;
  service_discount_code: string;
}

const BookingList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
    service_discount_code: searchValues.service_discount_code ?? '',
  });

  const ServiceReservationAPI = new ServiceReservationResource();

  const bookingListData: any = useQuery(
    [
      'booking-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
        service_discount_code: filterParams.service_discount_code,
      },
    ],
    () => {
      let { status, keyword, service_discount_code } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (status) queryParams.status = status;
      if (keyword) queryParams.keyword = keyword;
      if (service_discount_code)
        queryParams.service_discount_code = service_discount_code;

      return ServiceReservationAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      status: '',
      service_discount_code: '',
    }));
    history.push(routes.bms.booking.list);
  }

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} | {strings.all_bookings}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
              {strings.all_bookings}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box>
          <Heading size="md" textTransform="capitalize">
            {strings.bookings}
          </Heading>
        </Box>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.discount_code}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <DebounceInput
                    name="service_discount_code"
                    setFilterParams={setFilterParams}
                    initialValue={filterParams.service_discount_code}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.status}</FormLabel>
                <Select
                  name="status"
                  value={filterParams.status}
                  placeholder={`${strings.select} ${strings.status}`}
                  onChange={handleInputChange}>
                  <option value="pending">{strings.pending}</option>
                  <option value="confirmed">{strings.confirmed}</option>
                  <option value="cancelled">{strings.cancelled}</option>
                </Select>
              </FormControl>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.booking_number}</Th>
                  <Th>{strings.services}</Th>
                  <Th>{strings.booking_date}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.discount_code}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.actions}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {bookingListData?.data?.data?.map(
                  (
                    {
                      id,
                      reservation,
                      status,
                      bookings,
                      booking_date,
                      customer_name,
                      service_discount,
                    }: ReservationBooking,
                    index: number
                  ) => (
                    <Tr key={id}>
                      <Td>{startingSN + index}</Td>
                      <Td
                        fontWeight="medium"
                        color="gray.500"
                        _hover={{ color: 'gray.600' }}>
                        <RouterLink
                          to={
                            routes.bms.booking.details.replace(
                              ':id',
                              id.toString()
                            ) + search
                          }>
                          {reservation?.booking_number ?? '-'}
                        </RouterLink>
                      </Td>
                      <Td>
                        {bookings?.length > 0 &&
                          truncateString(
                            bookings
                              .map((ele: any) => ele.service_name)
                              .join(', '),
                            28
                          )}
                      </Td>
                      <Td>{booking_date ?? '-'}</Td>
                      <Td>{customer_name ?? '-'}</Td>
                      <Td>{service_discount?.code ?? '-'}</Td>
                      <Td>
                        <Text
                          color={selectColorScheme(status)}
                          textTransform="uppercase"
                          fontWeight="medium"
                          fontSize="sm">
                          {strings[status] ?? '-'}
                        </Text>
                      </Td>

                      <Td>
                        <RouterLink
                          to={
                            routes.bms.booking.details.replace(
                              ':id',
                              id.toString()
                            ) + search
                          }>
                          <Tooltip hasArrow label={strings.view}>
                            <IconButton
                              icon={<BiShow />}
                              variant="link"
                              aria-label={strings.view}
                              color="green.300"
                              minW="6"
                            />
                          </Tooltip>
                        </RouterLink>
                      </Td>
                    </Tr>
                  )
                )}
                {bookingListData.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={bookingListData}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default BookingList;
