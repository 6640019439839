import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import {
  MembershipLevelSchema,
  ThirdPartyPartnerSchema,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import PartnerGoodiesCheckboxGroup from './PartnerGoodiesCheckboxGroup';

interface Props {
  data?: MembershipLevelSchema;
}

const MembershipLevelForm: React.FC<Props> = (props) => {
  let { data } = props;

  const thirdPartyPartnerAPI = new ThirdPartyPartnerResource();

  const thirdPartyPartnerListData = useQuery({
    queryKey: ['third-party-partner-list-all'],
    queryFn: async () => {
      const response = await thirdPartyPartnerAPI.getAll();
      return response.data;
    },
  });

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext<MembershipLevelSchema>();

  useEffect(() => {
    if (!data) return;
    setValue('name', data.name);
    setValue('description', data.description);
    setValue('brief_description', data.brief_description);
    setValue('is_internal', data.is_internal);
    setValue('is_active', data.is_active);
    setValue('booking_discount', data.booking_discount || 0);
    setValue('final_cleaning_discount', data.final_cleaning_discount || 0);
    setValue('laundry_discount', data.laundry_discount || 0);
    setValue(
      'website_condition_discount',
      data.website_condition_discount || 0
    );
    setValue('night_spent', data.night_spent || 0);
    const euroSpent = data.euro_spent_in_cents
      ? Number(Number(data.euro_spent_in_cents / 100).toFixed(2))
      : 0;
    setValue('euro_spent_in_cents', euroSpent);
    setValue('number_of_booking', data.number_of_booking);
  }, [data, setValue]);

  return (
    <Stack direction="column" spacing="4">
      <Stack direction="column" spacing="8" sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(1,1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>{strings.name_membership_level}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.required_membership_level,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.name_membership_level}
              />
              {errors?.name?.message && (
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem rowSpan={[1, 2]}>
            <FormControl h="82%">
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description')}
                placeholder={strings.description}
                h="full"
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>{strings.brief_description}</FormLabel>
              <Textarea
                {...register('brief_description')}
                placeholder={strings.brief_description}
                defaultValue={data?.brief_description ?? ''}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <Grid
              gridTemplateColumns={['repeat(1,1fr)', 'repeat(3,1fr)']}
              gap={4}
              justifyContent="space-between">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.active}</FormLabel>
                  <Controller
                    control={control}
                    name="is_active"
                    render={({ field: { name, value, onChange } }) => (
                      <Switch
                        name={name}
                        isChecked={value}
                        onChange={onChange}
                        size="md"
                        colorScheme="primary"
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.internal}</FormLabel>
                  <Controller
                    control={control}
                    name="is_internal"
                    render={({ field: { name, value, onChange } }) => (
                      <Switch
                        name={name}
                        isChecked={value}
                        onChange={onChange}
                        size="md"
                        colorScheme="primary"
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem rowSpan={[1, 2]}>
            <FormControl>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                uploadedFiles={data?.icon}
                accept="image/png, image/svg"
                fileKey="icons"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <Grid
              gap={4}
              justifyContent="space-between"
              gridTemplateColumns={['repeat(1,1fr)', 'repeat(3,1fr)']}>
              <GridItem>
                <FormLabel>{strings.night_spent}</FormLabel>
                <Controller
                  control={control}
                  name="night_spent"
                  render={({ field }) => (
                    <Input
                      type="number"
                      min={0}
                      maxWidth="100"
                      borderColor="gray.100"
                      {...field}
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel>{strings.number_of_booking}</FormLabel>
                <Controller
                  control={control}
                  name="number_of_booking"
                  render={({ field }) => (
                    <Input
                      type="number"
                      min={0}
                      maxWidth="100"
                      borderColor="gray.100"
                      {...field}
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel>{strings.amount_spent}</FormLabel>
                <Controller
                  control={control}
                  name="euro_spent_in_cents"
                  render={({ field }) => (
                    <InputGroup>
                      <InputLeftElement>&euro;</InputLeftElement>
                      <Input
                        type="number"
                        min={0}
                        step="0.01"
                        maxWidth="100"
                        borderColor="gray.100"
                        {...field}
                      />
                    </InputGroup>
                  )}
                />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Stack>
      <Stack sx={wrapperStyles}>
        <Grid gap={4} gridTemplateColumns={['repeat(1,1fr)', 'repeat(2,1fr)']}>
          <GridItem>
            <FormControl isInvalid={!!errors.website_condition_discount}>
              <>
                <FormLabel>{strings.website_condition_discount}</FormLabel>
                <Input
                  type="number"
                  {...register('website_condition_discount')}
                  min={0}
                  defaultValue={data?.website_condition_discount}
                  maxWidth="100"
                />
              </>
              <FormErrorMessage>
                {errors.website_condition_discount &&
                  errors.website_condition_discount?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormLabel>{strings.special_conditions_on_the_website}</FormLabel>
            <Grid
              gridTemplateColumns={['repeat(1,1fr)', 'repeat(3,1fr)']}
              gap="4">
              <GridItem>
                <FormControl isInvalid={!!errors.final_cleaning_discount}>
                  <Flex flexDir={['row', 'column']} gridGap="4">
                    <Input
                      type="number"
                      {...register('final_cleaning_discount')}
                      min={0}
                      defaultValue={data?.final_cleaning_discount}
                      maxWidth="100"
                    />

                    <FormLabel mt={['2', '4']}>
                      {strings.final_cleaning_discount}
                    </FormLabel>
                  </Flex>
                  <FormErrorMessage>
                    {errors.final_cleaning_discount &&
                      errors.final_cleaning_discount.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.booking_discount}>
                  <Flex flexDir={['row', 'column']} gridGap="4">
                    <Input
                      type="number"
                      {...register('booking_discount')}
                      min={0}
                      defaultValue={data?.booking_discount}
                      maxWidth="100"
                    />

                    <FormLabel mt={['2', '4']}>
                      {strings.booking_discount}
                    </FormLabel>
                  </Flex>
                  <FormErrorMessage>
                    {errors.booking_discount && errors.booking_discount.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.laundry_discount}>
                  <Flex flexDir={['row', 'column']} gridGap="4">
                    <Input
                      type="number"
                      {...register('laundry_discount')}
                      min={0}
                      defaultValue={data?.laundry_discount}
                      maxWidth="100"
                    />

                    <FormLabel mt={['2', '4']}>
                      {strings.laundry_discount}
                    </FormLabel>
                  </Flex>
                  <FormErrorMessage>
                    {errors.laundry_discount && errors.laundry_discount.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Stack>
      <Stack sx={wrapperStyles}>
        <Heading size="sm">
          {strings.extra_goodies} ({strings.third_party_partner}):
        </Heading>
        <Grid gap="4" templateColumns={['repeat(1, 1fr)', 'repeat(5, 1fr)']}>
          {thirdPartyPartnerListData?.data?.data?.map(
            (thirdPartyPartnerData: ThirdPartyPartnerSchema, index: number) => (
              <PartnerGoodiesCheckboxGroup
                data={thirdPartyPartnerData}
                key={index}
                selectedValue={data?.partner_goodies}
              />
            )
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default MembershipLevelForm;
