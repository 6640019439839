import Resource from 'api/resource';
import axios from 'axios';
import { CRMMembershipListQuery } from 'constants/schema';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class CRMContactsResource extends Resource {
  constructor() {
    super('crm/contacts');
  }

  mergeContacts(formdata: any) {
    return http({
      url: `/crm/contact-merges`,
      method: 'POST',
      data: formdata,
    });
  }
  mergeDetails(id: string | number, query?: any) {
    return http({
      url: `/crm/contact-merges/${id}`,
      method: 'GET',
      params: query,
    });
  }

  exportCsv(query?: any) {
    return http({
      url: `${this.uri}/export-to-csv`,
      method: 'GET',
      params: query,
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    });
  }

  membershipList(query?: CRMMembershipListQuery) {
    if (cancel) {
      cancel();
    }
    return http({
      url: '/crm/membership-level',
      method: 'GET',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
}

export { CRMContactsResource as default };
