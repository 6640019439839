import { Flex, Stack, Text } from '@chakra-ui/layout';
import { Box, Heading, Spinner } from '@chakra-ui/react';
import BookingDashboardResource from 'api/booking_dashboard';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  BookingDashboardFilterParams,
  CustomerBookingNightCountSummary,
} from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { cleanData } from 'utils';

type Props = {
  filterParams: BookingDashboardFilterParams;
};
const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload || payload.length === 0) return null;
  const value = payload[0];
  return (
    <Flex flexDir="column" bg="white" p={2} rounded={4} fontSize={12}>
      <Text color="black">
        {strings.total_nights}: {value.payload.total_nights_spent}
      </Text>
      <Text>
        {strings.no_of_customer}: {value.payload.count}
      </Text>
    </Flex>
  );
};

const RenderContent: React.FC<Props> = ({ filterParams }) => {
  const bookingDashboardAPI = new BookingDashboardResource();

  const { data: nightCount, isLoading } = useQuery<
    CustomerBookingNightCountSummary[]
  >({
    queryKey: ['total-night-count-summary', filterParams],
    queryFn: async () => {
      const response = await bookingDashboardAPI.customerBookingNightCount(
        cleanData(filterParams)
      );
      return response.data;
    },
  });

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" minH={250}>
        <Spinner />
      </Flex>
    );
  }
  if (!nightCount || nightCount?.length === 0) {
    return (
      <Flex justifyContent="center" p={2} h="full" alignItems="center">
        <Text textTransform="capitalize">{strings.no_data_found}</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" alignItems="center">
      <ResponsiveContainer height={300}>
        <BarChart data={nightCount}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="total_nights_spent" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill="rgba(227, 160, 8, 1)" radius={4} />
        </BarChart>
      </ResponsiveContainer>
      <Text color="black" textTransform="capitalize">
        {strings.total_nights_stayed}
      </Text>
    </Flex>
  );
};
const TotalNightChart: React.FC<Props> = ({ filterParams }) => {
  return (
    <Stack sx={wrapperStyles}>
      <Box pb={4}>
        <Heading size="md" textTransform="capitalize">
          {strings.total_nights_of_customer_stays}
        </Heading>
        <Text color="blackAlpha.500">
          {strings.total_nights_of_customer_stays_description}
        </Text>
      </Box>
      <RenderContent filterParams={filterParams} />
    </Stack>
  );
};

export default TotalNightChart;
